import { getAuth } from "firebase/auth";

export const createCheckoutSession = async (stripeId: string, noOfAircrafts: number, noOfPilots: number, isYearly: boolean, email: string): Promise<any> => {
    const auth = getAuth();

    // Get the ID token of the current user
    const idToken = await auth.currentUser?.getIdToken(/* forceRefresh */ true);

    if (!idToken) {
        throw new Error("Unable to get ID token. User may not be authenticated.");
    }

    // Perform the fetch request and return its response
    const res = await fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL}/createCheckoutSession`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userId: stripeId,
            noOfPilots: noOfPilots,
            noOfAircrafts: noOfAircrafts,
            isYearly: isYearly,
            email: email
        }),
    });

    // Check if the response is successful
    if (!res.ok) {
        // throw new Error(`Failed to create checkout session: ${res.statusText}`);
        alert(`Failed to create checkout session`)
    }

    // Parse and return the JSON response
    const data = await res.json();
    return data;
};

export const upgradeSubscription = async (stripeId: string, noOfAircrafts: number, noOfPilots: number, isYearly: boolean, email: string): Promise<any> => {
    const auth = getAuth();

    // Get the ID token of the current user
    const idToken = await auth.currentUser?.getIdToken(/* forceRefresh */ true);

    if (!idToken) {
        throw new Error("Unable to get ID token. User may not be authenticated.");
    }

    // Perform the fetch request and return its response
    const res = await fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL}/upgradeSubscription`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userId: stripeId,
            noOfPilots: noOfPilots,
            noOfAircrafts: noOfAircrafts,
            isYearly: isYearly,
            email: email
        }),
    });

    // Check if the response is successful
    if (!res.ok) {
        // throw new Error(`Failed to create checkout session: ${res.statusText}`);
        alert("Failed to upgrade subscription")
    }

    return true;
};