import { useEffect, useState } from "react";
import { Button, TextField, Card, IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { MultiFileUploadWidget } from "../../../components/multiFileUpload";
import { FirebaseFileDownloader } from "../../../components/fileDownload";
import { useLoadingOverlay } from "../../../loading";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { useAllAircraftMXAttachments } from "../../../hooks/useAttachments";
import { AircraftMxLogAttachmentsModel } from "../../../models/logAttachments";
import { CustomButton } from "../../../components/button";
import { AircraftMxLogAttachmentsCollection } from "../../../keys";
import EditIcon from '@mui/icons-material/Edit';
import { CustomButtonFill } from "../../../components/buttonfill";

interface Section {
    order: number;
    sectionTitle: string;
    docs: string[];
}

export const DocumentManager = ({ aircraftId, permissionPower }: { aircraftId: string, permissionPower: number }) => {
    const [sections, setSections] = useState<Section[]>([]);
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const [editMode, setEditMode] = useState(false)

    const handleAddSection = () => {
        setSections([...sections, { order: sections.length + 1, sectionTitle: "", docs: [] }]);
    };

    const handleTitleChange = (index: number, title: string) => {
        const newSections = [...sections];
        newSections[index].sectionTitle = title;
        setSections(newSections);
    };

    const handleAddDoc = (index: number) => {
        const newSections = [...sections];
        newSections[index].docs.push("");
        setSections(newSections);
    };

    const handleDocChange = (sectionIndex: number, docIndex: number, value: string) => {
        const newSections = [...sections];
        newSections[sectionIndex].docs[docIndex] = value;
        setSections(newSections);
    };

    const handleRemoveDoc = (sectionIndex: number, docIndex: number) => {
        const newSections = [...sections];
        newSections[sectionIndex].docs.splice(docIndex, 1);
        setSections(newSections);
    };

    const handleRemoveSection = (index: number) => {
        const newSections = sections.filter((_, i) => i !== index);
        setSections(newSections.map((s, i) => ({ ...s, order: i + 1 })));
    };

    const moveSection = (index: number, direction: "up" | "down") => {
        const newSections = [...sections];
        if (direction === "up" && index > 0) {
            [newSections[index - 1], newSections[index]] = [newSections[index], newSections[index - 1]];
        } else if (direction === "down" && index < newSections.length - 1) {
            [newSections[index], newSections[index + 1]] = [newSections[index + 1], newSections[index]];
        }
        setSections(newSections.map((s, i) => ({ ...s, order: i + 1 })));
    };

    const { data: mxAttachmentDoc, refreshAllAircraftMXAttachments } = useAllAircraftMXAttachments(aircraftId);
    const defaultSections = [
        { order: 1, sectionTitle: "Airworthiness", docs: [] },
        { order: 1, sectionTitle: "Registration", docs: [] },
        { order: 1, sectionTitle: "Radio Operator License (for International)", docs: [] },
        { order: 1, sectionTitle: "Operating Manuals and Limitations", docs: [] },
        { order: 1, sectionTitle: "Weight & Balance Info", docs: [] },
        { order: 1, sectionTitle: "STC's", docs: [] }
    ];

    useEffect(() => {
        setSections(mxAttachmentDoc?.sections ?? defaultSections)
    }, [mxAttachmentDoc])

    const handleMultifileUpload = async (e: any) => {
        const db = getFirestore();
        const { name, value } = e;

        // var attachments: AircraftMxLogAttachmentsModel = {
        //     aircraftId: aircraftId,
        //     attachments: [...mxAttachmentDoc?.attachments ?? [], ...value]
        // }

        // await setDoc(doc(db, AircraftMxLogAttachmentsCollection, aircraftId), attachments)
        refreshAllAircraftMXAttachments();
    }

    return (
        <div className="w-full">
            {!editMode && permissionPower >= 4 && <div className="mb-2">
                <Button onClick={() => { setEditMode(true) }} startIcon={<EditIcon />} className="mb-4">
                    Add/Edit
                </Button>
            </div>}


            {!editMode && sections.map((section, index) => (
                <div key={index} className="border rounded mb-5 p-3">
                    <h3 className="font-bold">{section.sectionTitle}</h3>
                    <div className="flex flex-col">
                        {section.docs.length > 0 ? section.docs.map((doc, docIndex) => (
                            <FirebaseFileDownloader filePath={doc} key={docIndex} showDelete={false}
                            />
                        )) : <p className="mt-5 text-[gray] text-[10px]">No uploaded records.</p>}
                    </div>
                </div>
            ))}

            {editMode && <div>
                {sections.map((section, index) => (
                    <div key={index} className="border rounded mb-3 p-3">
                        <div className="flex items-center gap-2">
                            <TextField
                                label={`Title`}
                                variant="outlined"
                                fullWidth
                                value={section.sectionTitle}
                                onChange={(e) => handleTitleChange(index, e.target.value)}
                            />
                            <div className="flex flex-col">
                                <IconButton onClick={() => moveSection(index, "up")} disabled={index === 0}>
                                    <KeyboardDoubleArrowUpIcon />
                                </IconButton>
                                <IconButton onClick={() => moveSection(index, "down")} disabled={index === sections.length - 1}>
                                    <KeyboardDoubleArrowDownIcon />
                                </IconButton>
                            </div>

                            <IconButton onClick={() => handleRemoveSection(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                        <div className="flex flex-col">
                            {section.docs.map((doc, docIndex) => (
                                <FirebaseFileDownloader filePath={doc} key={docIndex} showDelete={false}
                                // onDelete={async (filePath: string) => {
                                //     showOverlay()
                                //     const db = getFirestore();

                                //     // var attachments: AircraftMxLogAttachmentsModel = {
                                //     //     aircraftId: aircraftId,
                                //     //     attachments: [...mxAttachmentDoc?.attachments.filter(f => f !== filePath) ?? []]
                                //     // }

                                //     // await setDoc(doc(db, AircraftMxLogAttachmentsCollection, aircraftId), attachments)
                                //     // refreshAllAircraftMXAttachments();
                                //     hideOverlay()
                                // }} 
                                />
                            ))}
                        </div>
                        <div className="mt-3"></div>
                        <MultiFileUploadWidget
                            id={"mx-log files" + index}
                            label={"Upload Mx Log files"}
                            folderName={`MxDocuments`}
                            // onChange={(e: string[]) => handleMultifileUpload({ name: "attachments", value: e })}
                            onChange={(e: string[]) => {
                                console.log(index, sections[index].docs)
                                sections[index].docs = [...sections[index].docs, ...e]
                            }}
                        />
                    </div>
                ))}
                <Button onClick={handleAddSection} startIcon={<AddIcon />} className="mt-4">
                    Add Section
                </Button>

                <div className="mt-5 ml-auto mb-0 pb-5 flex gap-2">
                    <CustomButtonFill text='Save' onClick={async () => {
                        showOverlay();
                        const db = getFirestore();

                        var attachments: AircraftMxLogAttachmentsModel = {
                            aircraftId: aircraftId,
                            sections: sections
                        }
                        await setDoc(doc(db, AircraftMxLogAttachmentsCollection, aircraftId), attachments)
                        refreshAllAircraftMXAttachments()
                        setEditMode(false)
                        hideOverlay()
                    }} />
                    <CustomButton text='Cancel' onClick={() => { setEditMode(false) }} />
                </div>
            </div>}

            {/* <pre className="mt-4 p-2 bg-gray-100 border rounded">{JSON.stringify(sections, null, 2)}</pre> */}
        </div>
    );
}
