export const ContactCard = ({ name, email, phone }: { name: string, email: string, phone: string }) => {
    return (
        <div className="p-2 border rounded-2xl text-center items-center flex flex-col md:flex-row mb-2">
            <span className="text-lg font-bold text-gray-900">{name}</span>
            <span className="px-3 hidden md:block">|</span>
            <span className="text-blue-600">
                <a href={`mailto:${email}`} className="hover:underline">
                    {email}
                </a>
            </span>
            <span className="px-3  hidden md:block">|</span>
            <span className="text-green-600 mt-1">
                <a href={`tel:${phone}`} className="hover:underline">
                    {phone}
                </a>
            </span>
        </div>
    );
}
