import { Card, Modal } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { PdfGenerator } from "../../../components/pdfGenerator";
import { AircraftModel } from "../../../models/aircraft";
import { convertToBase64, getFileDownloadUrl } from "../../../components/firebaseImage";
import { useUserById } from "../../../hooks/useUser";
import { useMxById } from "../../../hooks/useMx";
import { useEffect, useState } from "react";
import { UserModel } from "../../../models/user";
import {
    Text,
    View,
    StyleSheet,
    Image
} from "@react-pdf/renderer";

export const MxLogPdfGeneratorModal = ({ onClose, aircraft, formValues, tt, tsmoh, tsmoh_engine2, mxId }:
    { aircraft: AircraftModel, formValues: any, tt: number, tsmoh: number, tsmoh_engine2: number, mxId: string, onClose: () => void }) => {
    const [signature, setSignature] = useState<string>()
    const { data: user, isLoading } = useUserById(formValues?.maintainerId ?? "")
    const { data: mxLog, isLoading: isLoadingMx } = useMxById(mxId)
    const [logoUrl, setLogoUrl] = useState<unknown>()

    useEffect(() => {
        verifySignature()
    }, [mxLog])

    useEffect(() => {
        if (user) {
            setLogo(user)
        }
    }, [user])

    const setLogo = async (user: UserModel) => {
        var logoUrl = await getFileDownloadUrl(user?.maintainerInfo?.companyLogo)
        const base64Image = await convertToBase64(logoUrl);
        setLogoUrl(base64Image)
    }

    const verifySignature = () => {
        if (mxLog?.signature) {
            const signData = mxLog?.maintainerFullLegalName + "\n" + [(mxLog?.aAndP ? "A&P" : ""), mxLog?.certificate, (mxLog?.ia ? "IA" : ""), (mxLog?.owner ? "Owner" : "")].filter(e => e != "").join(" ")
            setSignature(signData)
        }

        // console.log(mxLog?.signature, ":", mxLog?.maintainerFullLegalName + " " + (mxLog?.aAndP ? "A&P" : "") + " " + mxLog?.certificate + " " + (mxLog?.ia ? "IA" : "") + " " + (mxLog?.owner ? "Owner" : ""))
        // const verify = crypto.createVerify('RSA-SHA256');
        // verify.update(signData)
        // var isVerified = verify.verify(process.env.REACT_APP_SIGN_PUBLIC_KEY!, mxLog?.signature!, 'base64');
        // if (isVerified) {
        //     setSignature(signData)
        // }
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    return <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card className='' sx={style}>
            <div className='flex flex-row justify-between '>
                <h1 className="text-3xl mb-5">MX as PDF</h1>
                <CloseIcon className='hover:cursor-pointer' onClick={onClose}></CloseIcon>
            </div>
            <div className="h-[90vh] w-[75vw] overflow-scroll">
                {!isLoading && !isLoadingMx &&
                    <PdfGenerator name={`mxlog-${formValues?.date}.pdf`} >
                        <AircraftMaintenanceLog
                            aircraft={aircraft} formValues={formValues} signature={signature ?? ""}
                            user={user!} tsmoh={tsmoh} tt={tt} logoUrl={logoUrl} />
                    </PdfGenerator>}
            </div>
        </Card>
    </Modal>
}

const AircraftMaintenanceLog = ({ user, aircraft, formValues, tt, tsmoh, signature, logoUrl }:
    { user: UserModel, aircraft: AircraftModel, formValues: any, tt: number, tsmoh: number, signature: string, logoUrl?: any }) => {

    return (
        <View style={styles.container}>
            {user && (
                <View style={styles.header}>
                    <View style={styles.companyInfo}>
                        {<Image style={styles.companyLogo} src={logoUrl} />}
                        {/* {user?.maintainerInfo?.companyLogo && <FirebaseImage img={user?.maintainerInfo?.companyLogo} classes="w-[80px] h-[51px] ml-0" />} */}
                        <Text style={styles.companyName}>{user?.maintainerInfo?.companyName}</Text>
                    </View>
                    <View style={styles.companyDetails}>
                        <Text>{user?.maintainerInfo?.companyAddress}</Text>
                        <Text>Phone: {user?.maintainerInfo?.companyPhoneNumber}</Text>
                    </View>
                </View>
            )}

            <View style={styles.aircraftInfo}>
                <View style={styles.infoColumn}>
                    <Text style={styles.aircraftAndEngine}>AC:</Text>
                    <Text style={styles.aircraftAndEngineDetails}>{aircraft?.aircraftId}</Text>
                </View>
                <View style={styles.infoColumn}>
                    <Text style={styles.aircraftAndEngine}>S/N:</Text>
                    <Text style={styles.aircraftAndEngineDetails}>{aircraft?.aircraftSerialNumber}</Text>
                </View>
                <View style={styles.infoColumn}>
                    <Text style={styles.aircraftAndEngine}>Eng 1:</Text>
                    <Text style={styles.aircraftAndEngineDetails}>{aircraft?.engineModel}</Text>
                </View>
                <View style={styles.infoColumn}>
                    <Text style={styles.aircraftAndEngine}>S/N:</Text>
                    <Text style={styles.aircraftAndEngineDetails}>{aircraft?.engineSerialNumber}</Text>
                </View>
                <View style={styles.infoColumn}>
                    <Text style={styles.aircraftAndEngine}>Prop:</Text>
                    <Text style={styles.aircraftAndEngineDetails}>{aircraft?.propellerModel}</Text>
                </View>
            </View>

            {aircraft?.dualEngine && (
                <View style={styles.aircraftInfo}>
                    <View style={styles.infoColumn}>
                        <Text style={styles.aircraftAndEngine}></Text>
                        <Text style={styles.aircraftAndEngineDetails}></Text>
                    </View>
                    <View style={styles.infoColumn}>
                        <Text style={styles.aircraftAndEngine}></Text>
                        <Text style={styles.aircraftAndEngineDetails}></Text>
                    </View>
                    <View style={styles.infoColumn}>
                        <Text style={styles.aircraftAndEngine}>Eng 2:</Text>
                        <Text style={styles.aircraftAndEngineDetails}>{aircraft?.engineModel_engine2}</Text>
                    </View>
                    <View style={styles.infoColumn}>
                        <Text style={styles.aircraftAndEngine}>S/N:</Text>
                        <Text style={styles.aircraftAndEngineDetails}>{aircraft?.engineSerialNumber_engine2}</Text>
                    </View>
                    <View style={styles.infoColumn}>
                        <Text style={styles.aircraftAndEngine}>Prop:</Text>
                        <Text style={styles.aircraftAndEngineDetails}>{aircraft?.propellerModel_engine2}</Text>
                    </View>
                </View>
            )}

            <View style={styles.dateInfo}>
                <Text style={styles.statLeft}>Date: {formValues?.date}</Text>
                {
                    aircraft.hourMeter === "HOBBS" ?
                        <Text style={styles.stat}>Hobbs: {formValues?.hobbs}</Text> :
                        <Text style={styles.stat}>Tach: {formValues?.tachtime}</Text>
                }

                <Text style={styles.stat}>TT: {tt}</Text>
                <Text style={styles.statRight}>TSMOH: {tsmoh}</Text>
            </View>

            {formValues?.description && <View style={styles.description}>
                <Text>{formValues?.description}</Text>
            </View>
            }
            <View style={styles.signatureContainer}>
                <Text style={styles.bold}>X</Text>
                <View style={styles.dashedLine}></View>
            </View>

            {signature && (
                <View style={styles.signatureDetails}>
                    <Text style={styles.signatureText}>Digitally signed:</Text>
                    <Text style={styles.signature}>{signature}</Text>
                </View>
            )}

            <Text style={styles.footer} >
                Digitally signed and recorded in Airlogbooks.com. A physical ink signature is required to maintain this document in the aircraft’s permanent logbook.
            </Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 6,
        paddingTop: 3,
        letterSpacing: 0.5,
        fontFamily: 'Helvetica'
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 18,
    },
    companyInfo: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    companyLogo: {
        width: 45,
        marginLeft: 0,
        marginRight: 25,
        fontWeight: 'bold',
        objectFit: 'contain'
    },
    companyName: {
        fontSize: 16,
        fontWeight: 'extrabold',
        marginTop: -15,
    },
    companyDetails: {
        textAlign: 'right',
        fontSize: 10,
    },
    bold: {
        fontWeight: 'bold',
        fontSize: 8
    },
    aircraftInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    infoColumn: {
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        width: '20%', // Ensures equal spacing for five columns
    },
    aircraftAndEngine: {
        fontSize: 10,
        fontWeight: 'ultrabold',
        textDecoration: 'underline',
        marginBottom: 3,
    },
    aircraftAndEngineDetails: {
        fontSize: 11,
        // marginTop: -5,
    },
    dateInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        textAlign: 'center',
        // marginTop: 3,
        borderRadius: 4,
        marginBottom: 5
    },
    stat: {
        fontWeight: 'bold',
        borderWidth: 1,
        // borderRadius: 3,
        borderColor: '#D3D3D3',
        fontSize: 10,
        width: '100%',
        padding: 3,
        paddingBottom: 6,
        paddingTop: 6
    },
    statLeft: {
        fontWeight: 'bold',
        borderWidth: 1,
        // borderRadius: 3,
        borderColor: '#D3D3D3',
        fontSize: 10,
        width: '100%',
        padding: 3,
        paddingBottom: 6,
        paddingTop: 6,
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3
    },
    statRight: {
        fontWeight: 'bold',
        borderWidth: 1,
        // borderRadius: 3,
        borderColor: '#D3D3D3',
        fontSize: 10,
        width: '100%',
        padding: 3,
        paddingBottom: 6,
        paddingTop: 6,
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3
    },
    description: {
        borderWidth: 1,
        borderColor: '#D3D3D3',
        borderRadius: 3,
        padding: 5,
        fontSize: 9,
    },
    signatureContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 25,
        width: 130,
        marginLeft: 'auto',
        fontSize: 13
    },
    dashedLine: {
        borderBottomWidth: 1,
        borderBottomStyle: 'dashed',
        flex: 1,
        marginTop: 5,
        marginBottom: 3,
    },
    signatureDetails: {
        textAlign: 'right',
        fontSize: 10,
        fontWeight: 'bold',
    },
    signatureText: {
        fontSize: 5,
    },
    signature: {
        fontSize: 8,
    },
    footer: {
        textAlign: 'center',
        fontSize: 8,
        color: '#999',
        fontWeight: 'bold',
        marginTop: 10,
    },
});


// old version
{/* <div className="p-x-6 pt-3 tracking-[0.5px] word-spacing-[1px]">
                            {user && <div className="flex items-center justify-between mb-5">
                                <div className="flex items-center space-x-3">
                                    {aircraft?.image && <FirebaseImage img={user?.maintainerInfo?.companyLogo} classes="w-[80px] h-[51px] ml-0" />}
                                    <p className="text-[18px] font-bold mt-[-15px]">{user?.maintainerInfo?.companyName}</p>
                                </div>

                                <div className="text-right text-[13px]">
                                    <p>{user?.maintainerInfo?.companyAddress}</p>
                                    <p><b>Phone:</b> {user?.maintainerInfo?.companyPhoneNumber}</p>
                                </div>
                            </div>}

                            <div className="grid grid-cols-5 text-center mb-3">
                                <div className="underline text-[12px] font-bold">AC:</div>
                                <div className="underline text-[12px] font-bold">S/N:</div>
                                <div className="underline text-[12px] font-bold">Eng 1:</div>
                                <div className="underline text-[12px] font-bold">S/N:</div>
                                <div className="underline text-[12px] font-bold">Prop:</div>

                                <div className="text-[15px] mt-[-5px]">{aircraft?.aircraftId}</div>
                                <div className="text-[15px] mt-[-5px]">{aircraft?.aircraftSerialNumber}</div>
                                <div className="text-[15px] mt-[-5px]">{aircraft?.engineModel}</div>
                                <div className="text-[15px] mt-[-5px]">{aircraft?.engineSerialNumber}</div>
                                <div className="text-[15px] mt-[-5px]">{aircraft?.propellerModel}</div>
                            </div>

                            {aircraft?.dualEngine && (
                                <div className="grid grid-cols-5 text-center">
                                    <div className="underline text-[12px] font-bold"></div>
                                    <div className="underline text-[12px] font-bold"></div>
                                    <div className="underline text-[12px] font-bold">Eng 2:</div>
                                    <div className="underline text-[12px] font-bold">S/N:</div>
                                    <div className="underline text-[12px] font-bold">Prop:</div>

                                    <div className="text-[15px]"></div>
                                    <div className="text-[15px]"></div>
                                    <div className="text-[15px]">{aircraft?.engineModel_engine2}</div>
                                    <div className="text-[15px]">{aircraft?.engineSerialNumber_engine2}</div>
                                    <div className="text-[15px]">{aircraft?.propellerModel_engine2}</div>
                                </div>
                            )}

                            <div className="grid grid-cols-4 rounded text-center mt-3">
                                <div className="border rounded-l pb-3 text-[13px]">
                                    <b>Date:</b> {formValues?.date}
                                </div>
                                <div className="border pb-3 text-[13px]">
                                    <b>Tach:</b> {formValues?.tachtime}
                                </div>
                                <div className="border pb-3 text-[13px]">
                                    <b>TT:</b> {tt}
                                </div>
                                <div className="border rounded-r pb-3 text-[13px]">
                                    <b>TSMOH:</b> {tsmoh}
                                </div>
                            </div>

                            <div className="border rounded px-3 mt-2 pb-2 text-[12px] font-smooth-antialiased">
                                <p className="whitespace-pre-line">{formValues?.description}</p>
                            </div>

                            <div className="mt-3 text-right ml-auto mr-0">
                                <div className="inline-flex items-center w-[180px]">
                                    <span className="text-[13px] font-bold mr-2 mt-[-12px]">X</span>
                                    <div className="border-b border-dashed flex-1 pt-5 mb-3"></div>
                                </div>
                            </div>

                            {signature && <div className="text-right pb-3 text-[10px] font-bold mr-2">
                                <p className="text-[7px]">Digitally signed:</p>
                                <pre>{signature}</pre>
                            </div>}

                            <div className="text-center text-[11px] text-gray-500 font-bold">
                                <p>Digitally signed and recorded in Airlogbooks.com.  A physical ink signature is required to maintain this document in the aircraft’s permanent logbook.</p>
                            </div>
                        </div> */}