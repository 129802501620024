import { collection, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import { UserModel } from "../models/user";
import { PilotLogbookBase, userKey, UsersCollection } from "../keys";

export const getAllPilots_firebase = async (pilotIds: string[]) => {
    const db = getFirestore()

    const data: UserModel[] = [];

    const q = query(collection(db, UsersCollection),
        where("type", "in", ["Pilot", "PilotAndMaintainer"]),
        where("email", "in", [localStorage.getItem(userKey)!, ...pilotIds]));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id } as UserModel);
    });

    return data;
}

export const getUser_firebase = async (userId: string): Promise<UserModel | null> => {
    const db = getFirestore()
    if (userId) {
        const docRef = doc(db, UsersCollection, userId!);
        const docSnap = await getDoc(docRef);

        return docSnap.exists() ? { ...docSnap.data(), id: docSnap.id } as UserModel : null
    }
    return null;
}

export const getUserBase_firebase = async () => {
    const db = getFirestore()

    const docRef = doc(db, PilotLogbookBase, localStorage.getItem(userKey)!);
    const docSnap = await getDoc(docRef);

    return docSnap.exists() ? docSnap.data() : null
}

export const isUserAvailable_firebase = async (userId: string) => {
    if (userId) {
        const db = getFirestore()

        const docRef = doc(db, UsersCollection, userId);
        const docSnap = await getDoc(docRef);

        return docSnap.exists();
    }

    return false;
}