import { Avatar, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem, Switch } from "@mui/material"

import Avatarimg from '../assets/images/Pilot.jpeg';

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DashboardInner } from "./dashboardInner";
import { AdCompliances } from "./features/ad/adCompliances";
import { PilotLogbooks } from "./features/pilot/pilotLogbooks";
import { getAuth } from "firebase/auth";
import Logo from '../assets/images/logo.jpeg';
import { FirebaseImage } from "../components/firebaseImage";
import { PilotBringforward } from "./features/pilot/pilotBringForward";
import { CustomButtonFill } from "../components/buttonfill";
import { CustomButton } from "../components/button";
import { MxLogbooks } from "./features/mx/mxLogbook";
import { UserDataFormDialog } from "../userData/userDataformDialog";
import { useLoadingOverlay } from "../loading";
import { getUser_firebase, getUserBase_firebase } from "../services/user";
import { useUser } from "../hooks/useUser";
import { userNameKey } from "../keys";
import { PermissionsManagement } from "./features/permissions/permissionManagement";
import { Greeting } from "../components/greeting";
import { useNotifications } from "../hooks/useNotifications";
import { VerifyEmailDialog } from "./verifyEmailDialog";
import { Subscription } from "../auth/subscription";
import { useSubscriptionById } from "../hooks/useCustomerSubscription";

export const Dashboard = () => {
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    // const [user, setUser] = useState<any>()
    const [pilotBaseData, setPilotBaseData] = useState<any>();
    const [bringPilotForward, setBringPilotForward] = useState(false)
    const [openPilotBringForward, setOpenPilotBringForward] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [key, setKey] = useState('dashboard')

    const [isDiv1Visible, setDiv1Visible] = useState(true);
    const [isDiv2Visible, setDiv2Visible] = useState(true);
    const [showAllNotifications, setShowAllNotifications] = useState(false);

    const { data: notifications } = useNotifications();

    const { showOverlay, hideOverlay } = useLoadingOverlay();

    const auth = getAuth();
    const navigate = useNavigate();

    const { data: user, refreshUser } = useUser();
    const { data: subscription, isLoading } = useSubscriptionById(user?.email ?? "")

    useEffect(() => {
        if (user && !isLoading && (!subscription || (subscription?.status !== "trialing" && subscription?.status !== "active"))) {

            navigate("/subscriptions")
        }
    }, [subscription, user, isLoading])

    useEffect(() => {
        getUser();
        // checkHack();
    }, [])

    const checkHack = () => {
        setInterval(() => {
            if (window.outerHeight - window.innerHeight > 200 || window.outerWidth - window.innerWidth > 200) {
                alert("Developer tools detected!");
                window.close();
            }
        }, 1000);
    }

    useEffect(() => {
        if (user && (user.type == "Pilot" || user.type == "PilotAndMaintainer")) {
            getUserBase();
        }
    }, [user])

    const getUser = async () => {
        showOverlay()
        var userData = await getUser_firebase(auth.currentUser?.email!);

        if (userData != null) {
            localStorage.setItem(userNameKey, `${userData?.firstName} ${userData?.lastName}`)
            hideOverlay()
        } else {
            navigate("/profile")
            hideOverlay()
        }
    }

    const getUserBase = async () => {
        var userBaseData = await getUserBase_firebase()

        if (userBaseData != null) {
            setPilotBaseData(userBaseData)
        } else {
            setOpenPilotBringForward(true)
        }
    }

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickOpenPilotBringForward = () => {
        setOpenPilotBringForward(true);
    };

    const handleClosePilotBringForward = () => {
        setOpenPilotBringForward(false);
    };

    return (
        <>
            <Card className="m-3 p-5 min-h-[96vh] w-[100vw]">
                <div className="flex">
                    <img src={Logo} alt="Air Loggbooks"
                        className="w-[15%] h-[15%] md:w-[11%] md:h-[11%] cursor-pointer" onClick={() => navigate("/")} />
                    {/*w-[55px] h-[55px] sm:w-[80px] sm:h-[80px] md:w-[130px] md:h-[130px] lg:w-[150px] lg:h-[135px] xl:w-[150px] xl:h-[135px] */}

                    <div className="flex flex-col">
                        <div className="flex flex-row justify-between w-[85vw]">
                            <div className="flex flex-col mt-[-15px]">
                                <span className="text-[1.1rem] sm:text-[2rem] md:text-[2rem] lg:text-[2.5rem] text-[#235e8a] p-[0px]
                                ml-[10px] sm:ml-[20px] md:ml-[25px] lg:ml-[30px] mr-5">
                                    Fly Smart - Maintain Easy - Comply with Confidence</span>
                            </div>
                            {/* <div className="flex float-right mt-6 mr-2">
                                <Badge badgeContent={4} color="primary">
                                    <NotificationsIcon color="action" />
                                </Badge>
                            </div> */}
                            <div className="flex float-right mr-3">

                                <div className="flex">
                                    {/* <Badge badgeContent={4} className="mt-6 mr-2" color="primary">
                                        <NotificationsIcon color="action" />
                                    </Badge> */}

                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                    >
                                        {user?.image ? (
                                            <Avatar alt="Remy Sharp"  >
                                                <FirebaseImage img={user.image} classes="" />
                                            </Avatar>
                                        ) : (
                                            <Avatar alt="Remy Sharp" src={Avatarimg} />
                                        )}
                                        <div className="ml-2 flex flex-row">
                                            <span className="font-semibold text-[0px] md:text-[12px]">{user?.firstName} {user?.lastName}</span>
                                        </div>
                                    </IconButton>

                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <div className="ml-2 flex flex-row">
                                            <span className="font-bold text-[13px]">{user?.firstName} {user?.lastName}</span>
                                        </div>
                                        <hr className="border-t border-gray-300 mt-3 border-1" />
                                        <MenuItem onClick={() => {
                                            setOpenEditModal(true)
                                        }}> Edit My Profile</MenuItem>

                                        <MenuItem onClick={() => {
                                            navigate("/permissions")
                                        }}>Manage Permissions</MenuItem>
                                        <MenuItem onClick={() => {
                                            navigate("/subscriptions")
                                        }}>Manage Subscription</MenuItem>

                                        <hr className="border-t border-gray-300 mt-3 border-1" />
                                        <MenuItem onClick={() => {
                                        }}><label>
                                                Show Aircraft:
                                                <Switch defaultChecked onChange={() => setDiv1Visible(!isDiv1Visible)} />
                                            </label></MenuItem>

                                        <MenuItem onClick={() => {
                                        }}><label>
                                                Show Pilots:
                                                <Switch defaultChecked onChange={() => setDiv2Visible(!isDiv2Visible)} />
                                            </label></MenuItem>

                                        <MenuItem onClick={() => {
                                        }}><label>
                                                Show All Notifications:
                                                <Switch onChange={() => setShowAllNotifications(!showAllNotifications)} />
                                            </label></MenuItem>
                                        <hr className="border-t border-gray-300 border-1" />

                                        <MenuItem onClick={() => {
                                            localStorage.clear()
                                            auth.signOut()
                                        }}>Logout</MenuItem>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                        <div className="border border-[1px] ml-0 md:ml-[30px] border-[#235e8a]"></div>
                        {location.pathname == "/" && <div className="hidden md:block"><Greeting name={user?.firstName ?? ""} /></div>}

                    </div>
                </div>

                {location.pathname == "/" && <div className="block md:hidden"><Greeting name={user?.firstName ?? ""} /></div>}

                <div className="w-[100%]">
                    {openPilotBringForward && auth.currentUser?.emailVerified &&
                        <Dialog
                            open={openPilotBringForward}
                            onClose={() => setOpenPilotBringForward(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Great! Welcome to Airlogbooks.com!"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Now, grab your current logbook and let’s get your pilot history up to date. Click here to bring forward your logbook totals.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <CustomButton onClick={() => {
                                    setOpenPilotBringForward(false)
                                }} text="Later" />
                                <CustomButtonFill onClick={() => {
                                    setOpenPilotBringForward(false)
                                    setBringPilotForward(true)
                                }} text="continue" />
                            </DialogActions>
                        </Dialog>
                        // <div className="mx-5 mt-2 mb-2">
                        //     <Alert severity="info" className="cursor-pointer" onClick={() => { setBringPilotForward(true) }}>
                        //         Great! Welcome to Airlogbooks.com! Now, grab your current logbook and let’s get your pilot history up to date. Click here to bring forward your logbook totals.
                        //     </Alert>
                        // </div>
                    }
                    {user && !user.forceValidation && !auth.currentUser?.emailVerified && <VerifyEmailDialog />}

                    {componentToRender(location.pathname, key, isDiv1Visible, isDiv2Visible, showAllNotifications)}
                    {/* <Routes>
                        <Route path="/" element={<DashboardInner
                            isDiv1Visible={isDiv1Visible} isDiv2Visible={isDiv2Visible} showAllNotifications={showAllNotifications} />} />
                        <Route path="/mx-log/:aircraftId" element={<MxLogbooks />} />
                        <Route path="/ad-compliances" element={<AdCompliances />} />
                        <Route path="/pilot-logbook" element={<PilotLogbooks />} />
                        <Route path="/permissions" element={<PermissionsManagement />} />
                    </Routes> */}
                </div>
            </Card>
            {bringPilotForward && <PilotBringforward data={undefined} onClose={setBringPilotForward} />}
            {openEditModal && <UserDataFormDialog userData={user} onClose={(val) => {
                setOpenEditModal(val)
                setKey(location.pathname + Math.random().toString(36).substring(2, 5))
                refreshUser()
            }} />}
        </>
    );
}

const componentToRender = (path: string, key: string, isDiv1Visible: boolean, isDiv2Visible: boolean, showAllNotifications: boolean) => {
    if (path === '/') {
        return <div key={key}><DashboardInner
            isDiv1Visible={isDiv1Visible} isDiv2Visible={isDiv2Visible} showAllNotifications={showAllNotifications} /></div>;
    } else if (path.includes('/mx-log')) {
        return <div key={key}><MxLogbooks /></div>;
    } else if (path.includes('/ad-compliances')) {
        return <div key={key}><AdCompliances /></div>;
    } else if (path === '/pilots') {
        // return <Pilots />;
    } else if (path.includes('/pilot-logbook/')) {
        return <div key={key}><PilotLogbooks /></div>;
    } else if (path.includes('/permissions')) {
        return <div key={key}><PermissionsManagement /></div>;
    } else if (path.includes('/subscriptions')) {
        return <div key={key}><Subscription /></div>;
    }

    return <div></div>;
};