import { useQuery, useQueryClient } from "@tanstack/react-query";
import { PermissionModel } from "../models/permissionModel";
import { queryKeyAircraftPermissionOfUser, queryKeyPermissionOfAircraft, queryKeyPermissionOfPilot, queryKeyPermissionOfUser, userKey } from "../keys";
import { getAllPermissions_firebase, getAllPilotPermissions_firebase, getUserAircraftPermissions_firebase, getUserPermissions_firebase } from "../services/permission";
import { useState } from "react";

export const useAircraftPermissions = (aircraftId: string) => {
    const queryClient = useQueryClient()

    const query = useQuery<PermissionModel[]>({
        queryKey: [queryKeyPermissionOfAircraft, aircraftId],
        queryFn: () => getAllPermissions_firebase(aircraftId)
    })

    const invalidateAircraftPermissions = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyPermissionOfAircraft, aircraftId] });
    };

    return {
        ...query,
        refreshAircraftPermissions: invalidateAircraftPermissions,
    };
}

export const usePilotPermissions = (pilotId: string) => {
    const queryClient = useQueryClient()

    const query = useQuery<PermissionModel[]>({
        queryKey: [queryKeyPermissionOfPilot, pilotId],
        queryFn: () => getAllPilotPermissions_firebase(pilotId)
    })

    const invalidatePilotPermissions = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyPermissionOfPilot, pilotId] });
    };

    return {
        ...query,
        refreshPilotPermissions: invalidatePilotPermissions,
    };
}

export const useUserPermissions = () => {
    const queryClient = useQueryClient()
    const userId = localStorage.getItem(userKey)!

    const query = useQuery<PermissionModel[]>({
        queryKey: [queryKeyPermissionOfUser, userId],
        queryFn: () => getUserPermissions_firebase(userId)
    })

    const invalidateUserPermissions = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyPermissionOfUser, userId] });
    };

    return {
        ...query,
        refreshUserPermissions: invalidateUserPermissions,
    };
}
// Admin 5, Mechanic 4, Pilot 3, ViewOnly 1
{/* 
        Add SQUAWK => pilot, mechanic, owner
        Add MX minder => owner, mechanic
        Add MX =>  owner, mechanic
        Edit Aircraft => owner, mechanic
        Delete aircraft => owner

        Fix squawk =>  owner, mechanic
        Fix Mx minder =>  owner, mechanic
        Comply with AD =>  owner, mechanic
        pilot log => pilot, owner (not available if mechanic or viewonly aircrafts)
         */}
export const useUserAircraftPermissions = (aircraftId: string | undefined, owner: string) => {
    const queryClient = useQueryClient()
    const userId = localStorage.getItem(userKey)!
    const [permissionPower, setPermissionPower] = useState(1);

    const query = useQuery<PermissionModel | null>({
        queryKey: [queryKeyAircraftPermissionOfUser, userId, aircraftId],
        queryFn: async () => {
            if (owner === userId) {
                setPermissionPower(getPermissionPower("Admin"));
                return {
                    role: "Admin"
                } as PermissionModel
            }
            else if (aircraftId) {
                var permission = await getUserAircraftPermissions_firebase(userId, aircraftId)

                setPermissionPower(getPermissionPower(permission?.role))

                return permission;
            }
            else return null
        }
    })

    const invalidateUserPermissions = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyAircraftPermissionOfUser, userId] });
    };

    return {
        ...query,
        permissionPower: permissionPower,
        refreshUserPermissions: invalidateUserPermissions,
    };
}

export const getPermissionPower = (role: string | undefined) => {
    if (role == "Admin") {
        return 5
    } else if (role == "Mechanic") {
        return 4
    } else if (role == "Pilot") {
        return 3
    } else if (role == "ViewOnly") {
        return 1
    }
    return 1
}
