import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './auth/useAuth';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

interface ProtectedRouteProps {
  children: ReactNode;
}

const queryClient = new QueryClient();

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/signin" />;
  }

  return <><QueryClientProvider client={queryClient}>
    {children}
  </QueryClientProvider></>;
};

export default ProtectedRoute;
