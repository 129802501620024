import { useEffect, useState } from "react"
import { Grid, Card, Modal, IconButton, Tooltip } from "@mui/material"
import { ZoomIn, ZoomOut, Close, KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft } from "@mui/icons-material"
import { FirebaseImage } from "./firebaseImage"
import { cn } from "../userData/userData";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useLoadingOverlay } from "../loading";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { AircraftPublicDetailsModel } from "../models/logAttachments";
import { AircraftPublicDetailsCollection } from "../keys";
import CloseIcon from '@mui/icons-material/Close';
import { MultiFileUploadWidget } from "./multiFileUpload";
import { CustomButton } from "./button";
import { useAllAircraftImages } from "../hooks/useAttachments";

export const AlbumManage = ({ aircraftId }: { aircraftId: string }) => {
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [isZoomed, setIsZoomed] = useState(false);
    const [showAdd, setShowAdd] = useState(false);

    const { data: imageModel, refreshAllAircraftImages } = useAllAircraftImages(aircraftId);
    const images = imageModel?.images ?? [];

    const handleImageClick = (index: number) => {
        setSelectedIndex(index);
        setIsZoomed(false);
    };

    const handleCloseModal = () => {
        setSelectedIndex(null);
        setIsZoomed(false);
    };

    const toggleZoom = () => {
        setIsZoomed(!isZoomed);
    };

    const handlePrev = () => {
        if (selectedIndex !== null && selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
        }
    };

    const handleNext = () => {
        if (selectedIndex !== null && selectedIndex < images?.length - 1) {
            setSelectedIndex(selectedIndex + 1);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (selectedIndex !== null) {
                if (event.key === "ArrowLeft") handlePrev();
                if (event.key === "ArrowRight") handleNext();
                if (event.key === "Escape") handleCloseModal();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [selectedIndex]);

    return (
        <div className="container py-2">
            <div className="flex mb-3 sticky top-0 z-10">
                <span className="text-[14px] font-bold">Gallery</span>
                <div className="mt-[-3px] ml-3">
                    {
                        < Tooltip title="Add Images" arrow>
                            <div className="w-[25px] cursor-pointer" onClick={() => { setShowAdd(true) }} ><AddPhotoAlternateIcon /></div>
                        </Tooltip>
                    }
                </div>
            </div>

            <Grid container spacing={2}>
                {images.map((image, index) => (
                    <Grid item key={image}>
                        <Card
                            className="cursor-pointer transition-transform hover:scale-105 w-[60px] h-[60px]"
                            onClick={() => handleImageClick(index)}
                        >
                            <FirebaseImage
                                img={image}
                                classes={cn("w-[60px] h-[60px] object-cover rounded-lg cursor-pointer hover:scale-105 transition-transform ")} />
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {images.length <= 0 && <span className="text-[10px] items-center">-- No images available. --</span>}

            <Modal open={selectedIndex !== null} onClose={handleCloseModal} className="flex items-center justify-center">
                <div className="bg-white p-4 rounded-lg max-w-4xl w-full mx-4 relative flex flex-col items-center">
                    <div className="flex flex-row justify-between w-full">
                        <h1 className="text-xl">Preview</h1>
                        <IconButton onClick={handleCloseModal} className="ml-auto" size="small">
                            <Close />
                        </IconButton>
                    </div>

                    <div className={`overflow-hidden ${isZoomed ? "cursor-zoom-out" : "cursor-zoom-in"}`}>
                        <div onClick={toggleZoom} className="flex flex-col items-center">
                            {selectedIndex !== null && (
                                <FirebaseImage
                                    img={images[selectedIndex]}
                                    classes={`w-full h-auto transition-transform duration-300 max-h-[80vh] overflow-scroll ${isZoomed ? "scale-150" : "scale-100"}`} />
                            )}
                        </div>
                    </div>

                    <div className="flex flex-row items-center mt-2">
                        <IconButton onClick={handlePrev} disabled={selectedIndex === 0} size="small">
                            <KeyboardDoubleArrowLeft />
                        </IconButton>
                        <IconButton onClick={handleNext} disabled={selectedIndex === images.length - 1} size="small">
                            <KeyboardDoubleArrowRight />
                        </IconButton>
                        <IconButton onClick={toggleZoom} className="mt-2" size="small">
                            {isZoomed ? <ZoomOut /> : <ZoomIn />}
                        </IconButton>
                    </div>
                </div>
            </Modal>

            {showAdd && <AddAircraftImages aircraftId={aircraftId} onClose={() => {
                refreshAllAircraftImages()
                setShowAdd(false)
            }} aircraftImages={imageModel} />}
        </div>
    );
};


export const AddAircraftImages = ({ aircraftId, onClose, aircraftImages }: { aircraftId: string, onClose: () => void, aircraftImages?: AircraftPublicDetailsModel | undefined | null }) => {
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    const handleMultifileUpload = async (e: any) => {
        const db = getFirestore();
        const { name, value } = e;

        var attachments: AircraftPublicDetailsModel = {
            aircraftId: aircraftId,
            images: [...aircraftImages?.images ?? [], ...value]
        }

        await setDoc(doc(db, AircraftPublicDetailsCollection, aircraftId), attachments)
    }

    return <>
        <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card className='signup-card w-[1000px] min-h-[300px]' sx={style}>
                <div className='flex  flex-row justify-between'>
                    <h1 className="text-3xl mb-5">Aircraft Gallery</h1>
                    <CloseIcon className='hover:cursor-pointer' onClick={() => onClose()}></CloseIcon>
                </div>
                <div className="overflow-scroll max-h-[70vh]">
                    <MultiFileUploadWidget
                        id="image files"
                        label={"Upload Images"}
                        folderName={`AircraftImages`}
                        accept="image/*"
                        // value={formValues.image}
                        onChange={(e: string[]) => handleMultifileUpload({ name: "images", value: e })}
                    />
                </div>

                <div className="mt-5 ml-auto">
                    <CustomButton text='OK' onClick={() => { onClose() }} />
                </div>
            </Card>
        </Modal>
    </>
}