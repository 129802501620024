import { Alert, Box, Card, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material"
import { addDoc, collection, doc, getFirestore, setDoc } from "firebase/firestore"
import CloseIcon from '@mui/icons-material/Close';
import { AircraftCollection, PilotLogbookCollection, userKey, userNameKey } from "../../../keys"
import { useEffect, useState } from "react"
import { useLoadingOverlay } from "../../../loading"
import { CustomButtonFill } from "../../../components/buttonfill";
import { aircraftCategory } from "../../../dropdownItems";
import { formatDateToYYYYMMDDToday, getDateDiff } from "../../../utils/dateutils";
import { SquawksModal } from "../squawk/squawksModal";
import { CustomButton } from "../../../components/button";
import { toFixedNumber } from "../../../utils/numberUtils";
import { useAircraftsForPilotLog } from "../../../hooks/useAircrafts";
import { AircraftModel } from "../../../models/aircraft";

export const AddPilotLog = (prop: { onClose: (val: boolean) => void, openAddAircraft: () => void }) => {
    const [formValues, setFormValues] = useState({
        "tachStart": 0,
        "tachEnd": 0,
        "hobbsStart": 0,
        "hobbsEnd": 0,
        "tach2Start": 0,
        "tach2End": 0,
        "date": new Date().toISOString().split("T")[0],
        "aircraftId": null,
        "year": "",
        "make&Model": "",
        "departureAirport": null,
        "arrivalAirport": null,
        "aircraftCategory": "",
        "totalTime": 0,
        "dayLanding": null,
        "nightLanding": null,
        "pic": null,
        "sic": null,
        "crossCountry": null,
        "actualInst": null,
        "simulatedInst": null,
        "night": null,
        "instructor": null,
        "dualReceived": null,
        "solo": null,
        "remarks": null,
        notInAirlogbooks: false
    });
    const db = getFirestore()
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const [selectedAircraft, setSelectedAircraft] = useState<AircraftModel>()
    const [showSquawks, setShowSquawks] = useState(false)

    const { data: aircrafts, refreshAircraftForPilot } = useAircraftsForPilotLog();

    const [formErrors, setFormErrors] = useState({
        "tachStart": false,
        "tachEnd": false,
        "hobbsStart": false,
        "hobbsEnd": false,
        "tach2Start": false,
        "tach2End": false,
        "date": false,
        "aircraftId": false,
        "year": false,
        "make&Model": false,
        "departureAirport": false,
        "arrivalAirport": false,
        "aircraftCategory": false,
        "totalTime": false,
        "dayLanding": false,
        "nightLanding": false,
        "pic": false,
        "sic": false,
        "crossCountry": false,
        "actualInst": false,
        "simulatedInst": false,
        "night": false,
        "instructor": false,
        "dualReceived": false,
        "solo": false,
        "remarks": false
    });
    const [customErrors, setcustomErrors] = useState("");

    const TachtimeError_heigherThanCurrent = "Tach time should be higher than current Tach value. Please select a past date to add a historical data."
    const TachtimeError_higerThanEnd = "Tach End should be higher than Tach start."

    const HobbsError_heigherThanCurrent = "Hobbs should be higher than current current value. Please select a past date to add a historical data."
    const HobbsError_higerThanEnd = "Hobbs End should be higher than Hobbs start."

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    const handleChange = (e: any) => {

        const { name, value } = e.target;

        if (typeof (value) != "object") {
            if (value.trim() === '') {
                setFormErrors({
                    ...formErrors,
                    [name]: true,
                });
            } else {
                setFormErrors({
                    ...formErrors,
                    [name]: false,
                });
            }
        }

        var custom = {}

        if (name == "aircraftId") {
            if (value === "ADD NEW") {
                prop.onClose(false);
                prop.openAddAircraft()
            } else {
                const aircraftData = aircrafts?.find(a => a.aircraftId === value);

                if (aircraftData) {
                    setSelectedAircraft(aircraftData)

                    custom = {
                        ...{
                            "tachStart": aircraftData.currentTach,
                            "tachEnd": aircraftData.currentTach,
                            "hobbsStart": aircraftData.hobbs,
                            "hobbsEnd": aircraftData.hobbs,
                            "tach2Start": aircraftData.currentTach_engine2,
                            "tach2End": aircraftData.currentTach_engine2,
                            "date": new Date().toISOString().split("T")[0],
                            "aircraftId": null,
                            "year": "",
                            "make&Model": "",
                            "departureAirport": null,
                            "arrivalAirport": null,
                            "aircraftCategory": "",
                            "totalTime": 0,
                            "dayLanding": null,
                            "nightLanding": null,
                            "pic": null,
                            "sic": null,
                            "crossCountry": null,
                            "actualInst": null,
                            "simulatedInst": null,
                            "night": null,
                            "instructor": null,
                            "dualReceived": null,
                            "solo": null,
                            "remarks": null,
                            notInAirlogbooks: false
                        },
                        "make&Model": `${aircraftData.make}, ${aircraftData.model}`,
                        year: aircraftData.year,
                        aircraftCategory: aircraftData.aircraftCategory
                    }
                }
            }
        }

        setFormValues({
            ...formValues,
            ...custom,
            [name]: value,
        });

        // if (name == "date") {
        //     if (getDateDiff(formatDateToYYYYMMDDToday(), value) >= 0 && selectedAircraft != null) {
        //         setMinTach(selectedAircraft.currentTach)
        //         setMinTach2(selectedAircraft.currentTach_engine2)
        //         if (
        //             formValues.tachStart != null && formValues.tachStart < selectedAircraft.currentTach
        //         ) {
        //             setcustomErrors(TachtimeError)
        //         } else if (
        //             formValues.tach2Start != null && formValues.tach2Start < selectedAircraft.currentTach_engine2
        //         ) {
        //             setcustomErrors(TachtimeError)
        //         } else {
        //             setcustomErrors("")
        //         }
        //     }
        //     else {
        //         setMinTach(0)
        //         setMinTach2(0)
        //         setcustomErrors("")
        //     }
        // }
    };

    const handleChangeCheckbox = (e: any) => {

        const { name, checked } = e.target;
        setFormValues({
            ...formValues,
            [name]: checked,
        });
    };

    const handleChangeNumber = (e: any) => {

        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: value ? parseFloat(value) : null
        });
    };

    const handleChangeNumberCustom = (e: any) => {

        const { name, value } = e.target;

        let totaltimeDiff = 0;
        var tachStart = name == 'tachStart' ? value : formValues.tachStart ?? 0
        var tachEnd = name == 'tachEnd' ? value : formValues.tachEnd ?? 0
        var hobbsStart = name == 'hobbsStart' ? value : formValues.hobbsStart ?? 0
        var hobbsEnd = name == 'hobbsEnd' ? value : formValues.hobbsEnd ?? 0

        if (tachEnd > 0 && tachStart > 0 && tachEnd > tachStart) {
            totaltimeDiff = tachEnd - tachStart
        } else if (hobbsEnd > 0 && hobbsStart > 0 && hobbsEnd > hobbsStart) {
            totaltimeDiff = hobbsEnd - hobbsStart
        }

        setFormValues({
            ...formValues,
            [name]: value ? parseFloat(value) : null,
            totalTime: toFixedNumber(totaltimeDiff)
        });
    };

    const getTachDiff = () => (formValues.tachEnd ?? 0) - (selectedAircraft?.currentTach ?? 0) // (flight.tachEnd ?? 0) - (flight.tachStart ?? 0)
    const getTach2Diff = () => selectedAircraft?.currentTach_engine2 ? (formValues.tach2End ?? 0) - (selectedAircraft?.currentTach_engine2 ?? 0) : 0 // (flight.tach2End ?? 0) - (flight.tach2Start ?? 0)
    const getHobbsDiff = () => (formValues.hobbsEnd ?? 0) - (selectedAircraft?.hobbs ?? 0) // (flight.hobbsEnd ?? 0) - (flight.hobbsStart ?? 0)

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        showOverlay()
        const flight = {
            ...formValues,
            pilotId: localStorage.getItem(userKey),
            pilot: localStorage.getItem(userNameKey)
        }

        await addDoc(collection(db, PilotLogbookCollection), flight);

        // update aircraft here
        if (!isHistoryLog(formValues.date) && !formValues.notInAirlogbooks) {
            let aircraftData = aircrafts?.find(a => a.aircraftId === formValues?.aircraftId)
            if (aircraftData) {
                // var hobbsdiff = (flight.hobbsEnd ?? 0) - (aircraftData.hobbs ?? 0) // (flight.hobbsEnd ?? 0) - (flight.hobbsStart ?? 0)

                // //engine 1
                // var tachDiff = (flight.tachEnd ?? 0) - (aircraftData.currentTach ?? 0) // (flight.tachEnd ?? 0) - (flight.tachStart ?? 0)

                // //engine 2 // TODO: fix
                // var tachDiff2 = (flight.tach2End ?? 0) - (aircraftData.currentTach_engine2 ?? 0) // (flight.tach2End ?? 0) - (flight.tach2Start ?? 0)

                var totaltimeDiff = getTachDiff() > getTach2Diff() ? getTachDiff() : getTach2Diff();

                if (aircraftData.hourMeter === "TACH") {
                    aircraftData = {
                        ...aircraftData,
                        currentTach: toFixedNumber(aircraftData.currentTach + getTachDiff()),
                        currentTach_engine2: aircraftData.dualEngine ? toFixedNumber(aircraftData.currentTach_engine2 + getTach2Diff()) : 0,
                        totalTime: toFixedNumber(aircraftData.totalTime + totaltimeDiff), // toFixedNumber(aircraftData.totalTime + flight.totalTime),
                        tsmoh: toFixedNumber(aircraftData.tsmoh + getTachDiff()),
                        tsmoh_engine2: aircraftData.dualEngine ? toFixedNumber(aircraftData.tsmoh_engine2 + getTach2Diff()) : 0
                    }
                }
                else if (aircraftData.hourMeter === "HOBBS") {
                    aircraftData = {
                        ...aircraftData,
                        hobbs: toFixedNumber(aircraftData.hobbs + getHobbsDiff()),
                        totalTime: toFixedNumber(aircraftData.totalTime + getHobbsDiff()), // toFixedNumber(aircraftData.totalTime + flight.totalTime),
                        tsmoh: toFixedNumber(aircraftData.tsmoh + getHobbsDiff()),
                        tsmoh_engine2: aircraftData.dualEngine ? toFixedNumber(aircraftData.tsmoh_engine2 + getHobbsDiff()) : 0
                    }
                }
                else if (aircraftData.hourMeter === "BOTHTACHHOBBS") {
                    aircraftData = {
                        ...aircraftData,
                        hobbs: toFixedNumber(aircraftData.hobbs + getHobbsDiff()),
                        currentTach: toFixedNumber(aircraftData.currentTach + getTachDiff()),
                        currentTach_engine2: aircraftData.dualEngine ? toFixedNumber(aircraftData.currentTach_engine2 + getTach2Diff()) : 0,
                        totalTime: toFixedNumber(aircraftData.totalTime + getHobbsDiff()), // toFixedNumber(aircraftData.totalTime + flight.totalTime),
                        tsmoh: toFixedNumber(aircraftData.tsmoh + getTachDiff()),
                        tsmoh_engine2: aircraftData.dualEngine ? toFixedNumber(aircraftData.tsmoh_engine2 + getTach2Diff()) : 0
                    }
                }

                await setDoc(doc(db, AircraftCollection, aircraftData.id), aircraftData)
            }
        }
        refreshAircraftForPilot();
        hideOverlay()

        prop.onClose(false);
    };

    useEffect(() => {
        if (selectedAircraft) {
            if (!isHistoryLog(formValues.date)) {
                const tachStart = formValues.tachStart ?? 0
                const tachEnd = formValues.tachEnd ?? 0
                const tach2Start = formValues.tach2Start ?? 0
                const tach2End = formValues.tach2End ?? 0
                const hobbsStart = formValues.hobbsStart ?? 0
                const hobbsEnd = formValues.hobbsEnd ?? 0

                var totlaTimeDiff = 0;

                if (selectedAircraft.hourMeter === "TACH") {
                    if (tachStart < (selectedAircraft.currentTach ?? 0)) setcustomErrors(TachtimeError_heigherThanCurrent)
                    else if (tachStart > tachEnd) setcustomErrors(TachtimeError_higerThanEnd)
                    else if (tach2Start < (selectedAircraft.currentTach_engine2 ?? 0)) setcustomErrors(TachtimeError_heigherThanCurrent)
                    else if (selectedAircraft.dualEngine && tach2Start > tach2End) setcustomErrors(TachtimeError_higerThanEnd)
                    else setcustomErrors("")

                    totlaTimeDiff = getTachDiff() > getTach2Diff() ? getTachDiff() : getTach2Diff();
                }
                else if (selectedAircraft.hourMeter === "HOBBS") {
                    if (hobbsStart < (selectedAircraft.hobbs ?? 0)) setcustomErrors(HobbsError_heigherThanCurrent)
                    else if (hobbsStart > hobbsEnd) setcustomErrors(HobbsError_higerThanEnd)
                    else setcustomErrors("")

                    totlaTimeDiff = getHobbsDiff()
                }
                else if (selectedAircraft.hourMeter === "BOTHTACHHOBBS") {
                    if (tachStart < (selectedAircraft.currentTach ?? 0)) setcustomErrors(TachtimeError_heigherThanCurrent)
                    else if (tachStart > tachEnd) setcustomErrors(TachtimeError_higerThanEnd)
                    else if (tach2Start < (selectedAircraft.currentTach_engine2 ?? 0)) setcustomErrors(TachtimeError_heigherThanCurrent)
                    else if (selectedAircraft.dualEngine && tach2Start > tach2End) setcustomErrors(TachtimeError_higerThanEnd)

                    else if (hobbsStart < (selectedAircraft.hobbs ?? 0)) setcustomErrors(HobbsError_heigherThanCurrent)
                    else if (hobbsStart > hobbsEnd) setcustomErrors(HobbsError_higerThanEnd)

                    else setcustomErrors("")

                    totlaTimeDiff = getHobbsDiff()
                }

                // setFormValues({ ...formValues, totalTime: toFixedNumber(totlaTimeDiff) })
            } else {
                setcustomErrors("");
            }
        } else {
            setcustomErrors("");
        }
    }, [formValues, selectedAircraft])

    const isHistoryLog = (date: string) => getDateDiff(formatDateToYYYYMMDDToday(), date) < 0;

    return (<Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card className='signup-card w-[90vw]' sx={style}>
            <div className='flex  flex-row justify-between'>
                <div className="flex gap-5 mb-5">
                    <h1 className="text-3xl">Add New Flight</h1> {selectedAircraft != null && <CustomButton text='SQUAWKS' onClick={() => { setShowSquawks(true) }} />}
                </div>

                <CloseIcon className='hover:cursor-pointer' onClick={() => prop.onClose(false)}></CloseIcon>
            </div>

            {customErrors != "" && <Alert severity="error"><span className="text-[red] mb-4">{customErrors}</span></Alert>}

            <div className='h-[80vh] overflow-scroll px-3 pt-2'>
                <Box
                    component="form"
                    sx={{ flexGrow: 1 }}
                    onSubmit={handleSubmit}
                    className=""
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={formValues.notInAirlogbooks} onChange={handleChangeCheckbox} name="notInAirlogbooks" />}
                                label="Aircraft not in AirLogbooks"
                            />
                        </Grid>

                        {formValues.notInAirlogbooks && <Grid item xs={12} md={3}>
                            <TextField
                                label="Aircraft ID"
                                name="aircraftId"
                                value={formValues.aircraftId}
                                onChange={handleChange}
                                error={formErrors.aircraftId}
                                className="w-full"
                            />
                        </Grid>}

                        {!formValues.notInAirlogbooks && <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                                <InputLabel id="dropdown-label" sx={{ fontSize: '0.7rem' }}>Aircraft ID</InputLabel>
                                <Select
                                    labelId="dropdown-label"
                                    name="aircraftId"
                                    value={formValues.aircraftId}
                                    label="Select Option"
                                    onChange={handleChange}
                                    required
                                    className="h-[50px]"
                                    sx={{ fontSize: '0.8rem' }}
                                    disabled={formValues.notInAirlogbooks}
                                >
                                    <MenuItem value={"ADD NEW"}>ADD NEW</MenuItem>
                                    {aircrafts?.map(l => <MenuItem value={l.aircraftId}>{l.aircraftId}</MenuItem>)}

                                </Select>
                            </FormControl>
                        </Grid>}

                        {!formValues.notInAirlogbooks && selectedAircraft && <Grid item xs={12} md={9}>
                            <div className="mt-3 flex flex-col md:flex-row gap-2 ">
                                {selectedAircraft?.hourMeter !== "HOBBS" && <div><b>Aircraft Tach Time:</b> {selectedAircraft?.currentTach ? `${selectedAircraft?.currentTach}(Engine 1)` : "-"} {selectedAircraft?.dualEngine ? `, ${selectedAircraft?.currentTach_engine2}(Engine 2)` : ""}</div>}
                                {selectedAircraft?.hourMeter !== "TACH" && <div><b>Hobbs:</b> {selectedAircraft?.hobbs ? `${selectedAircraft?.hobbs}` : "-"}</div>}
                            </div>
                        </Grid>}

                        <Grid item xs={12}>
                        </Grid>

                        {selectedAircraft?.hourMeter !== "TACH" && <>
                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Hobbs Start"
                                    name="hobbsStart"
                                    type="number"
                                    value={formValues.hobbsStart}
                                    onChange={handleChangeNumberCustom}
                                    error={formErrors.hobbsStart}
                                    className="w-full border rounded-md bg-[#C4DFF0]"
                                />
                            </Grid>

                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Hobbs End"
                                    name="hobbsEnd"
                                    type="number"
                                    value={formValues.hobbsEnd}
                                    onChange={handleChangeNumberCustom}
                                    error={formErrors.hobbsEnd}
                                    className="w-full border rounded-md bg-[#C4DFF0]"
                                />
                            </Grid>
                        </>}

                        {selectedAircraft?.hourMeter !== "HOBBS" && <>
                            <Grid item xs={12}>
                                <p className='font-bold mb-[-9px]'>
                                    Engine 1
                                </p>
                            </Grid>

                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Tach Start"
                                    name="tachStart"
                                    type="number"
                                    value={formValues.tachStart}
                                    onChange={handleChangeNumberCustom}
                                    error={formErrors.tachStart}
                                    className="w-full border rounded-md bg-[#C4DFF0]"
                                // inputProps={{ min: minTach }}
                                />
                            </Grid>

                            <Grid item xs={6} md={3}>
                                <TextField
                                    label="Tach End"
                                    name="tachEnd"
                                    type="number"
                                    value={formValues.tachEnd}
                                    onChange={handleChangeNumberCustom}
                                    error={formErrors.tachEnd}
                                    className="w-full border rounded-md bg-[#C4DFF0]"
                                />
                            </Grid>

                            {selectedAircraft?.dualEngine && <Grid item xs={12}>
                                <p className='font-bold mb-[-10px]'>
                                    Engine 2
                                </p>
                            </Grid>
                            }
                            {selectedAircraft?.dualEngine && <Grid item xs={6} md={3}>
                                <TextField
                                    label="Tach Start"
                                    name="tach2Start"
                                    type="number"
                                    value={formValues.tach2Start}
                                    onChange={handleChangeNumberCustom}
                                    error={formErrors.tach2Start}
                                    className="w-full border rounded-md bg-[#C4DFF0]"
                                // inputProps={{ min: minTach }}
                                />
                            </Grid>}

                            {selectedAircraft?.dualEngine && <Grid item xs={6} md={3}>
                                <TextField
                                    label="Tach End"
                                    name="tach2End"
                                    type="number"
                                    value={formValues.tach2End}
                                    onChange={handleChangeNumberCustom}
                                    error={formErrors.tach2End}
                                    className="w-full border rounded-md bg-[#C4DFF0]"
                                />
                            </Grid>}
                        </>}
                        <Grid item xs={12}></Grid>

                        <Grid item xs={6} md={3}>
                            <TextField
                                type="date"
                                name="date"
                                value={formValues.date}
                                onChange={handleChange}
                                label="Date"
                                error={formErrors.date}
                                helperText={formErrors.date ? 'Date is required' : ''}
                                className="w-full "
                                InputProps={{ inputProps: { max: new Date().toISOString().split("T")[0] } }}
                                required
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <TextField
                                label="Aircraft Year"
                                name="year"
                                type="number"
                                value={formValues.year}
                                onChange={handleChangeNumber}
                                error={formErrors.year}
                                className="w-full"
                            // required
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <TextField
                                label="Aircraft make And Model"
                                name="make&Model"
                                value={formValues["make&Model"]}
                                onChange={handleChange}
                                error={formErrors["make&Model"]}
                                className="w-full"
                                required
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <TextField
                                label="Departure Airport"
                                name="departureAirport"
                                value={formValues.departureAirport}
                                onChange={handleChange}
                                error={formErrors.departureAirport}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <TextField
                                label="Arrival Airport"
                                name="arrivalAirport"
                                value={formValues.arrivalAirport}
                                onChange={handleChange}
                                error={formErrors.arrivalAirport}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <FormControl fullWidth>
                                <InputLabel id="dropdown-label" sx={{ fontSize: '0.7rem' }}>Aircraft Category</InputLabel>
                                <Select
                                    labelId="dropdown-label"
                                    name="aircraftCategory"
                                    value={formValues.aircraftCategory}
                                    label="Select Option"
                                    onChange={handleChange}
                                    required
                                    className="h-[50px]"
                                    sx={{ fontSize: '0.8rem' }}
                                >
                                    {aircraftCategory.map(item => <MenuItem value={item.value}>{item.value}</MenuItem>)}

                                </Select>
                            </FormControl>
                        </Grid>



                        <Grid item xs={6} md={3}>
                            <TextField
                                label="Day Landing(s)"
                                name="dayLanding"
                                type="number"
                                value={formValues.dayLanding}
                                onChange={handleChangeNumber}
                                error={formErrors.dayLanding}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <TextField
                                label="Night Landing(s)"
                                name="nightLanding"
                                type="number"
                                value={formValues.nightLanding}
                                onChange={handleChangeNumber}
                                error={formErrors.nightLanding}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <TextField
                                label="Total Time"
                                name="totalTime"
                                type="number"
                                value={formValues.totalTime}
                                onChange={handleChangeNumber}
                                error={formErrors.totalTime}
                                className="w-full"
                                required
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <TextField
                                label="PIC"
                                name="pic"
                                type="number"
                                value={formValues.pic}
                                onChange={handleChangeNumber}
                                error={formErrors.pic}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <TextField
                                label="SIC"
                                name="sic"
                                type="number"
                                value={formValues.sic}
                                onChange={handleChangeNumber}
                                error={formErrors.sic}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={6} md={3}>
                            <TextField
                                label="XC (Cross Country)"
                                name="crossCountry"
                                type="number"
                                value={formValues.crossCountry}
                                onChange={handleChangeNumber}
                                error={formErrors.crossCountry}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <TextField
                                label="Actual Inst."
                                name="actualInst"
                                type="number"
                                value={formValues.actualInst}
                                onChange={handleChangeNumber}
                                error={formErrors.actualInst}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <TextField
                                label="Simulated Inst."
                                name="simulatedInst"
                                type="number"
                                value={formValues.simulatedInst}
                                onChange={handleChangeNumber}
                                error={formErrors.simulatedInst}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <TextField
                                label="Night"
                                name="night"
                                type="number"
                                value={formValues.night}
                                onChange={handleChangeNumber}
                                error={formErrors.night}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <TextField
                                label="Instructor/Dual Given"
                                name="instructor"
                                type="number"
                                value={formValues.instructor}
                                onChange={handleChangeNumber}
                                error={formErrors.instructor}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <TextField
                                label="Dual Received"
                                name="dualReceived"
                                type="number"
                                value={formValues.dualReceived}
                                onChange={handleChangeNumber}
                                error={formErrors.dualReceived}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <TextField
                                label="Solo"
                                name="solo"
                                type="number"
                                value={formValues.solo}
                                onChange={handleChangeNumber}
                                error={formErrors.solo}
                                className="w-full"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Remarks"
                                name="remarks"
                                value={formValues.remarks}
                                onChange={handleChange}
                                error={formErrors.remarks}
                                className="w-full custom-textarea"
                                rows={5}
                                multiline
                            />
                        </Grid>

                    </Grid>

                    <div className="mt-5 flex gap-3">
                        <CustomButtonFill text='Submit' disabled={
                            customErrors != ""} onClick={() => { }} />
                        {/* || formValues.tachStart === null || formValues.tachStart === undefined || (formValues.tachStart !== null && formValues.tachStart <= 0) */}
                    </div>

                </Box>
            </div>
            {showSquawks && selectedAircraft && <SquawksModal aircraftId={selectedAircraft?.id} permissionPower={3} onClose={() => { setShowSquawks(false) }} />}
        </Card>
    </Modal >)
}
