import { useQuery, useQueryClient } from "@tanstack/react-query"
import { AircraftPublicDetailsModel, AircraftMxLogAttachmentsModel, PilotLogAttachmentsModel } from "../models/logAttachments";
import { queryKeyAircraftImages, queryKeyAircraftMXAttachments, queryKeyPilotLogAttachments } from "../keys";
import { getAllAircraftImages_firebase, getAllAircraftMXAttachments_firebase, getPilotLogAttachments_firebase } from "../services/attachments";

export const useAllAircraftMXAttachments = (aircraftId: string) => {
    const queryClient = useQueryClient()

    const query = useQuery<AircraftMxLogAttachmentsModel | null>({
        queryKey: [queryKeyAircraftMXAttachments, aircraftId],
        queryFn: () => getAllAircraftMXAttachments_firebase(aircraftId),
    })

    const invalidateAllAircraftMXAttachments = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyAircraftMXAttachments, aircraftId] });
    };

    return {
        ...query,
        refreshAllAircraftMXAttachments: invalidateAllAircraftMXAttachments,
    };
}

export const useAllAircraftImages = (aircraftId: string) => {
    const queryClient = useQueryClient()

    const query = useQuery<AircraftPublicDetailsModel | null>({
        queryKey: [queryKeyAircraftImages, aircraftId],
        queryFn: () => getAllAircraftImages_firebase(aircraftId),
    })

    const invalidateAllAircraftImages = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyAircraftImages, aircraftId] });
    };

    return {
        ...query,
        refreshAllAircraftImages: invalidateAllAircraftImages,
    };
}

export const usePilotLogAttachments = (pilotId: string) => {
    const queryClient = useQueryClient()

    const query = useQuery<PilotLogAttachmentsModel | null>({
        queryKey: [queryKeyPilotLogAttachments, pilotId],
        queryFn: () => getPilotLogAttachments_firebase(pilotId),
    })

    const invalidatePilotLogAttachments = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyPilotLogAttachments, pilotId] });
    };

    return {
        ...query,
        refreshPilotLogAttachments: invalidatePilotLogAttachments,
    };
}