import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { CustomButtonFill } from "../components/buttonfill";
import Logo from '../assets/images/logo.jpeg';
import { Card, TextField } from "@mui/material";
import { createCheckoutSession, upgradeSubscription } from "../services/payments";
import { useLoadingOverlay } from "../loading";
import { useUser } from "../hooks/useUser";
import { useLocation } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { CustomBack } from "../components/backbutton";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { SubscriptionsDetailsCollection } from "../keys";
import { SubscriptionDetailsModel } from "../models/subscription";
import { useSubscriptionById } from "../hooks/useCustomerSubscription";

export interface SubscriptionSession {
    sessionId: string;
}

export interface FeatureResponse {
    success: boolean;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? "");

interface PlanOption {
    id: string;
    name: string;
    price: number;
    interval: 'month' | 'year';
}

export const Subscription: React.FC = () => {
    const { data: user } = useUser();
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const location = useLocation();

    const [noOfAircrafts, setNoOfAircrafts] = useState(1)
    const [noOfPilots, setNoOfPilots] = useState(1)
    const db = getFirestore()

    const { data: subscription, isLoading } = useSubscriptionById(user?.email ?? "")

    useEffect(() => {
        if(subscription){
            setNoOfAircrafts(subscription.noOfAircrafts)
            setNoOfPilots(subscription.noOfPilots)
            setSelectedPlan(subscription.isYearly ? plans[0] : plans[1])
        }
    }, [subscription])

    const plans: PlanOption[] = [
        { id: 'y', name: 'Yearly', price: 60, interval: 'year' },
        { id: 'm', name: 'Monthly', price: 6, interval: 'month' },
    ];
    const [selectedPlan, setSelectedPlan] = useState<PlanOption>(plans[1]);

    const handlePlanChange = (plan: PlanOption) => {
        if(!subscription){
        setSelectedPlan(plan);
        }
    };

    const handleSubscribe = async () => {
        showOverlay()
        if (!user) return;

        const subscriptionDetails: SubscriptionDetailsModel = {
            email: user.email,
            noOfAircrafts: noOfAircrafts,
            noOfPilots: noOfPilots,
            userId: user.stripeId,
            isYearly: selectedPlan.interval === 'year'
        } 
        await setDoc(doc(db, SubscriptionsDetailsCollection, user.email), subscriptionDetails)

        if(subscription){
            const res = await upgradeSubscription(user.stripeId, noOfAircrafts, noOfPilots, selectedPlan.interval === 'year', user.email)
            hideOverlay()
        }
        else{
        const response = await createCheckoutSession(user.stripeId, noOfAircrafts, noOfPilots, selectedPlan.interval === 'year', user.email)
        const { sessionId }: SubscriptionSession = response;

        const stripe = await stripePromise;
        if (stripe) {
            await stripe.redirectToCheckout({ sessionId: sessionId });
        }
        }
        hideOverlay()
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if(name == "aircrafts"){
            setNoOfAircrafts(value ? parseInt(value): 0)
        }else{
            setNoOfPilots(value ? parseInt(value): 0)
        }
    };

    return (
        <>
            <div className="signup-form1 flex justify-center md:mt-0 ">
                <div className="flex flex-col">
                    {location.pathname.includes("success") && <CustomBack backTo={'/'} />}
                    <Card className='signup-card '>
                        <img src={Logo} alt="Air Loggbooks" className="w-[100px] mt-[17px] m-auto" />

                        <h2 className="text-2xl font-bold mb-2 text-center text-gray-800"> Squadron Membership</h2>

                        {location.pathname.includes("success") ? <>
                            <div className="flex pt-6 w-[80vw] md:w-[500px]"><CheckCircleIcon sx={{ fontSize: 100 }} className="text-[green] m-auto" /></div>
                            <div className="mb-6 flex">
                                <span className="text-[25px] m-auto">Success</span>
                            </div>
                        </> :
                            location.pathname.includes("cancel") ? <>
                                <div className="flex pt-6 w-[80vw] md:w-[500px]"><CancelIcon sx={{ fontSize: 100 }} className="text-[red] m-auto" /></div>
                                <div className="mb-6 flex">
                                    <span className="text-[25px] m-auto">Canceled</span>
                                </div>
                            </> :
                                <>
                                    <h4 className="text-lg font-bold mb-3 text-center text-gray-800">Choose Your Plan</h4>
                                    <div className="flex gap-2 my-3">
                                    <TextField
                                            label="Number of Aircrafts"
                                            name="aircrafts"
                                            value={noOfAircrafts}
                                            onChange={handleChange}
                                            className="w-full"
                                            type="number"
                                            inputProps={{ min: 0, step: 1 }}
                                            required
                                        />
                                        <TextField
                                            label="Number of Pilots"
                                            name="pilots"
                                            value={noOfPilots}
                                            onChange={handleChange}
                                            className="w-full"
                                            type="number"
                                            inputProps={{ min: 0, step: 1 }}
                                            required
                                        />
                                    </div>
                                    <div className="space-y-4">
                                        {plans.map((plan) => (
                                            <div
                                                key={plan.id}
                                                className={`flex items-center justify-between p-4 rounded-lg cursor-pointer transition-colors ${selectedPlan.id === plan.id
                                                    ? 'bg-blue-100 border-2 border-blue-500'
                                                    : 'bg-gray-100 hover:bg-gray-200'
                                                    }`}
                                                onClick={() => handlePlanChange(plan)}
                                            >
                                                <div className="flex items-center">
                                                    <div
                                                        className={`w-5 h-5 rounded-full border-2 mr-3 ${selectedPlan.id === plan.id ? 'bg-blue-500 border-blue-500' : 'border-gray-400'
                                                            }`}
                                                    />
                                                    <div className="flex flex-col">
                                                        <span className="font-medium text-gray-700">{plan.name}</span>
                                                        <span className="text-[11px]">{noOfAircrafts} Aircrafts, {noOfPilots} Pilots </span>
                                                    </div>

                                                </div>
                                                <span className="font-bold text-gray-800">
                                                    ${plan.price * (noOfAircrafts + noOfPilots)}/{plan.interval === 'month' ? 'mo' : 'yr'}
                                                </span>
                                            </div>
                                        ))}
                                    </div>

                                    <div className="mt-6">
                                        <span className="text-[13px]">* Additional charges(6USD) will be added when create a additional aircraft & additional pilot</span>
                                    </div>

                                    <div className="flex flex-row mt-3">
                                        <CustomButtonFill
                                            text={subscription ? "Upgrade": "Subscribe Now"}
                                            onClick={() => handleSubscribe()}
                                        />
                                    </div>
                                </>
                        }

                    </Card>
                </div>

            </div>
        </>
    );
};

// export const Subscription: React.FC = () => {
//     const products = [
//       { id: 1, name: 'Product A', price: 9.99 },
//       { id: 2, name: 'Product B', price: 14.99 },
//     ];
  
//     return (
//       <div className="container mx-auto p-4">
//         <h1 className="text-3xl font-bold mb-6">Subscription Page</h1>
//         <SubscriptionUI products={products} />
//       </div>
//     );
//   };


// interface Product {
//     id: number;
//     name: string;
//     price: number;
//   }
  
//   interface SubscriptionUIProps {
//     products: Product[];
//   }
  
//   export const SubscriptionUI: React.FC<SubscriptionUIProps> = ({ products }) => {
//     const [quantities, setQuantities] = useState<number[]>(products.map(() => 0));
//     const [isYearly, setIsYearly] = useState<boolean>(false);
  
//     const handleQuantityChange = (index: number, value: number) => {
//       const newQuantities = [...quantities];
//       newQuantities[index] = value;
//       setQuantities(newQuantities);
//     };
  
//     const calculateTotal = () => {
//       return products.reduce((total, product, index) => {
//         return total + product.price * quantities[index];
//       }, 0) * (isYearly ? 12 : 1);
//     };
  
//     return (
//       <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md">
//         <h2 className="text-2xl font-bold mb-4">Subscription</h2>
//         {products.map((product, index) => (
//           <div key={product.id} className="mb-4">
//             <label className="block text-sm font-medium text-gray-700">{product.name}</label>
//             <input
//               type="number"
//               min="0"
//               value={quantities[index]}
//               onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
//               className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
//             />
//           </div>
//         ))}
//         <div className="mb-4">
//           <label className="flex items-center">
//             <input
//               type="checkbox"
//               checked={isYearly}
//               onChange={() => setIsYearly(!isYearly)}
//               className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50"
//             />
//             <span className="ml-2 text-sm text-gray-600">Yearly subscription (12 months)</span>
//           </label>
//         </div>
//         <div className="text-xl font-bold">
//           Total: ${calculateTotal().toFixed(2)} {isYearly ? 'per year' : 'per month'}
//         </div>
//       </div>
//     );
//   };