import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Modal, Radio, RadioGroup, Select, TextField, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { addDoc, collection, deleteDoc, doc, getDocs, getFirestore, query, setDoc, where } from 'firebase/firestore';
import { useLoadingOverlay } from '../../../loading';
import { toFixedNumber } from '../../../utils/numberUtils';
import { AircraftCollection, userKey } from '../../../keys';
import { CustomButtonFill } from '../../../components/buttonfill';
import { CustomButton } from '../../../components/button';
import { SearchableDropdown } from '../../../components/searchableDropdown';
import { make, model } from '../../aircraftOptions';
import { aircraftCategory } from '../../../dropdownItems';
import { Collapsible } from '../../../components/collapsable';
import { FileUploadWidget } from '../../../components/fileupload';
import DeleteIcon from '@mui/icons-material/Delete';
import { AircraftModel } from '../../../models/aircraft';
import { AlbumManage } from '../../../components/albumManage';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export const AddAircraft = (prop: { onClose: (val: boolean) => void, data: any, isEditMode: boolean, permissionPower: number }) => {
    const db = getFirestore()
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const [showEditConfirmation, setshowEditConfirmation] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [prop1Blades, setProp1Blades] = useState(1)
    const [prop2Blades, setProp2Blades] = useState(1)

    useEffect(() => {
        var prop1BladesNo = 1;

        if (prop.data.prop2SerialNumber && prop.data.prop2SerialNumber != "" && prop.data.prop2SerialNumber != null) {
            prop1BladesNo++
        }
        if (prop.data.prop3SerialNumber && prop.data.prop3SerialNumber != "" && prop.data.prop3SerialNumber != null) {
            prop1BladesNo++
        }
        if (prop.data.prop4SerialNumber && prop.data.prop4SerialNumber != "" && prop.data.prop4SerialNumber != null) {
            prop1BladesNo++
        }
        if (prop.data.prop5SerialNumber && prop.data.prop5SerialNumber != "" && prop.data.prop5SerialNumber != null) {
            prop1BladesNo++
        }
        setProp1Blades(prop1BladesNo)

        var prop2BladesNo = 1;

        if (prop.data.prop2SerialNumber_engine2 && prop.data.prop2SerialNumber_engine2 != "" && prop.data.prop2SerialNumber_engine2 != null) {
            prop2BladesNo++
        }
        if (prop.data.prop3SerialNumber_engine2 && prop.data.prop3SerialNumber_engine2 != "" && prop.data.prop3SerialNumber_engine2 != null) {
            prop2BladesNo++
        }
        if (prop.data.prop4SerialNumber_engine2 && prop.data.prop4SerialNumber_engine2 != "" && prop.data.prop4SerialNumber_engine2 != null) {
            prop2BladesNo++
        }
        if (prop.data.prop5SerialNumber_engine2 && prop.data.prop5SerialNumber_engine2 != "" && prop.data.prop5SerialNumber_engine2 != null) {
            prop2BladesNo++
        }
        setProp1Blades(prop1BladesNo)

    }, [])

    const [formValues, setFormValues] = useState<AircraftModel>({
        ...{
            "aircraftId": "",
            "year": 0,
            "make": "",
            "model": "",
            "aircraftSerialNumber": "",
            "engineMoake": "",
            "engineModel": "",
            "engineSerialNumber": "",
            "currentTach": 0,
            "tsmoh": 0,
            "engineLastAnnualDate": new Date().toISOString().split("T")[0],
            "lastOilChangeDate": new Date().toISOString().split("T")[0],
            "tachTimeAtLastOilChange": 0,
            "engineOilChangeDays": 0,
            "engineOilChangeHours": 0,
            "propellerMoake": "",
            "propellerModel": "",
            "propellerHubSerialNumber": "",
            "prop1SerialNumber": "",
            "prop2SerialNumber": "",
            "prop3SerialNumber": "",
            "prop4SerialNumber": "",
            "prop5SerialNumber": "",
            "lastOilChangedDateAndTachTime": new Date().toISOString().split("T")[0],

            "engineMoake_engine2": "",
            "engineModel_engine2": "",
            "engineSerialNumber_engine2": "",
            "currentTach_engine2": 0,
            "tsmoh_engine2": 0,
            "engineLastAnnualDate_engine2": new Date().toISOString().split("T")[0],
            "propellerMoake_engine2": "",
            "propellerModel_engine2": "",
            "propellerHubSerialNumber_engine2": "",
            "prop1SerialNumber_engine2": "",
            "prop2SerialNumber_engine2": "",
            "prop3SerialNumber_engine2": "",
            "prop4SerialNumber_engine2": "",
            "prop5SerialNumber_engine2": "",
            "lastOilChangeDate_engine2": new Date().toISOString().split("T")[0],
            "tachTimeAtLastOilChange_engine2": 0,
            "engineOilChangeDays_engine2": 0,
            "engineOilChangeHours_engine2": 0,
            "lastOilChangedDateAndTachTime_engine2": new Date().toISOString().split("T")[0],
            lastMxDate: new Date().toISOString().split("T")[0],

            "totalTime": 0,
            "hobbs": 0,
            "aircraftCategory": "",
            "lastAnnualDate": new Date().toISOString().split("T")[0],
            "ownerName": "",
            "ownerPhone": "",
            "ownerEmail": "",
            image: "",

            addedUser: localStorage.getItem(userKey),

            hourMeter: "TACH", // TACH, HOBBS, BOTHTACHHOBBS,
            dualEngine: false
        }, ...prop.data
    });

    const [formData, setFormData] = useState(prop.data)

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        console.log(name, value)

        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleRadio = (e: any) => {

    }

    const handleAutocompleteChange = (e: any) => {
        const { name, value } = e;

        setFormValues({
            ...formValues,
            [name]: value,
        });
    }

    const handleChangeNumber = (e: any) => {
        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: parseFloat(value),
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        let aircrft = {
            ...formValues,

            "currentTach": toFixedNumber(formValues.currentTach),
            "tsmoh": toFixedNumber(formValues.tsmoh),
            "currentTach_engine2": toFixedNumber(formValues.currentTach_engine2),
            "tsmoh_engine2": toFixedNumber(formValues.tsmoh_engine2),
        }

        // edit
        if (prop.data.id) {
            setFormData(aircrft)
            setshowEditConfirmation(true)
        }
        //create
        else {
            showOverlay()
            const data: any[] = [];
            aircrft = {
                ...aircrft,
                addedUser: localStorage.getItem(userKey)!,
            }

            const q = query(collection(db, AircraftCollection), where("aircraftId", "==", aircrft.aircraftId));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                data.push(doc.data());
            });

            if (data.length > 0) {
                alert(`Aircraft with id: ${aircrft.aircraftId} already exist!`)
                hideOverlay()
            } else {
                await addDoc(collection(db, AircraftCollection), aircrft);
                hideOverlay()
                prop.onClose(false);
            }
        }
    };

    const handleSubmitUpdate = async (isContinue: boolean) => {
        setshowEditConfirmation(false)
        if (isContinue) {
            showOverlay()
            await setDoc(doc(db, AircraftCollection, prop.data.id), formData)
            prop.onClose(false);
            hideOverlay()
        } else {

        }
    }

    return (
        <>
            {<Modal
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card className='signup-card w-[90vw] ' sx={style}>
                    <div className='flex  flex-row justify-between'>
                        <h1 className="text-3xl">{prop.isEditMode ? "Edit" : "Add"} Aircraft</h1>
                        <CloseIcon className='hover:cursor-pointer' onClick={() => prop.onClose(false)}></CloseIcon>
                    </div>
                    <div className='h-[80vh] overflow-scroll px-3'>
                        <div className='h-[80vh] overflow-scroll px-3'>
                            <Box
                                component="form"
                                sx={{ flexGrow: 1 }}
                                onSubmit={handleSubmit}
                                className="mt-4 pt-4"
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <p className='font-bold'>
                                            Hour Meter
                                        </p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl>
                                            <RadioGroup row value={formValues.hourMeter} name='hourMeter' onChange={handleChange}>
                                                <FormControlLabel value="TACH" control={<Radio />} label="Tach" />
                                                <FormControlLabel value="HOBBS" control={<Radio />} label="Hobbs" />
                                                <FormControlLabel value="BOTHTACHHOBBS" control={<Radio />} label="Tach & Hobbs" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <p className='font-bold mt-3'>
                                            Airframe
                                        </p>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Aircraft Id"
                                            name="aircraftId"
                                            value={formValues.aircraftId}
                                            onChange={handleChange}
                                            className="w-full"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Aircraft Year"
                                            name="year"
                                            type="number"
                                            value={formValues.year}
                                            onChange={handleChangeNumber}
                                            className="w-full"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SearchableDropdown
                                            options={{ options: make }}
                                            label={"Make"}
                                            value={formValues.make}
                                            onChange={(e: string) => handleAutocompleteChange({ name: "make", value: e })}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <SearchableDropdown
                                            options={{ options: model }}
                                            label={"Model"}
                                            value={formValues.model}
                                            onChange={(e: string) => handleAutocompleteChange({ name: "model", value: e })}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Aircraft Serial Number"
                                            name="aircraftSerialNumber"
                                            value={formValues.aircraftSerialNumber}
                                            onChange={handleChange}
                                            className="w-full"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label="Total Time"
                                            name="totalTime"
                                            type="number"
                                            value={formValues.totalTime}
                                            onChange={handleChangeNumber}
                                            className="w-full"
                                            required
                                        />
                                    </Grid>
                                    {formValues.hourMeter !== "TACH" && <Grid item xs={2}>
                                        <TextField
                                            label="Hobbs"
                                            name="hobbs"
                                            type="number"
                                            value={formValues.hobbs}
                                            onChange={handleChangeNumber}
                                            className="w-full"
                                        // disabled={formValues.hourMeter === "TACH"}
                                        />
                                    </Grid>}
                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <InputLabel id="dropdown-label" sx={{ fontSize: '0.7rem' }}>Aircraft Category</InputLabel>
                                            <Select
                                                labelId="dropdown-label"
                                                name="aircraftCategory"
                                                value={formValues.aircraftCategory}
                                                label="Select Option"
                                                onChange={handleChange}
                                                required
                                                className="h-[50px]"
                                                sx={{ fontSize: '0.8rem' }}
                                            >
                                                {aircraftCategory.map(l => <MenuItem value={l.value} key={l.value}>{l.value}</MenuItem>)}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            type="date"
                                            name="lastAnnualDate"
                                            value={formValues.lastAnnualDate}
                                            onChange={handleChange}
                                            label="Last Annual Inspection Date"
                                            className="w-full "
                                            InputProps={{ inputProps: { max: new Date().toISOString().split("T")[0] } }}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl>
                                            <RadioGroup row value={formValues.dualEngine} name='dualEngine' onChange={handleChange}>
                                                <FormControlLabel value={false} control={<Radio />} label="Single Engine" />
                                                <FormControlLabel value={true} control={<Radio />} label="Dual Engine" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className='font-bold'>
                                            Engine and Propeller
                                        </p>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SearchableDropdown
                                            options={{ options: make }}
                                            label={"Make"}
                                            value={formValues.engineMoake}
                                            onChange={(e: string) => handleAutocompleteChange({ name: "engineMoake", value: e })}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <SearchableDropdown
                                            options={{ options: model }}
                                            label={"Model"}
                                            value={formValues.engineModel}
                                            onChange={(e: string) => handleAutocompleteChange({ name: "engineModel", value: e })}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Engine Serial Number"
                                            name="engineSerialNumber"
                                            value={formValues.engineSerialNumber}
                                            onChange={handleChange}
                                            className="w-full"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        {formValues.hourMeter !== "HOBBS" && <TextField
                                            label="Current Tach"
                                            name="currentTach"
                                            type="number"
                                            value={formValues.currentTach}
                                            onChange={handleChangeNumber}
                                            className="w-full"
                                            required
                                        />}
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <div className='flex justify-end '>
                                            <p className='font-bold text-[11px] mb-[-10px]'>
                                                {"Recommended Oil Change Interval (Hours or Days - whichever comes first)"}
                                            </p>
                                        </div>

                                    </Grid> */}
                                    <Grid item xs={2}>
                                        <TextField
                                            label="Time since Major Overhaul"
                                            name="tsmoh"
                                            type="number"
                                            value={formValues.tsmoh}
                                            onChange={handleChangeNumber}
                                            className="w-full"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            type="date"
                                            name="engineLastAnnualDate"
                                            value={formValues.engineLastAnnualDate}
                                            onChange={handleChange}
                                            label="Last Annual Inspection Date"
                                            className="w-full "
                                            InputProps={{ inputProps: { max: new Date().toISOString().split("T")[0] } }}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            type="date"
                                            name="lastOilChangeDate"
                                            value={formValues.lastOilChangeDate}
                                            onChange={handleChange}
                                            label="Last Oil Change Date"
                                            className="w-full "
                                            InputProps={{ inputProps: { max: new Date().toISOString().split("T")[0] } }}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField
                                            label="Tach Time at Last Oil Change"
                                            name="tachTimeAtLastOilChange"
                                            type="number"
                                            value={formValues.tachTimeAtLastOilChange}
                                            onChange={handleChangeNumber}
                                            className="w-full"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Hours"
                                                    name="engineOilChangeHours"
                                                    type="number"
                                                    value={formValues.engineOilChangeHours}
                                                    onChange={handleChangeNumber}
                                                    className="w-full"
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Days"
                                                    name="engineOilChangeDays"
                                                    type="number"
                                                    value={formValues.engineOilChangeDays}
                                                    onChange={handleChangeNumber}
                                                    className="w-full"
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                        <p className='font-bold text-[11px]'>
                                            {"(Recommended Oil Change Interval - Whichever comes first)"}
                                        </p>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SearchableDropdown
                                            options={{ options: make }}
                                            label={"Propeller Make"}
                                            value={formValues.propellerMoake}
                                            onChange={(e: string) => handleAutocompleteChange({ name: "propellerMoake", value: e })}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <SearchableDropdown
                                            options={{ options: model }}
                                            label={"Propeller Model"}
                                            value={formValues.propellerModel}
                                            onChange={(e: string) => handleAutocompleteChange({ name: "propellerModel", value: e })}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="propeller Hub Serial Number"
                                            name="propellerHubSerialNumber"
                                            value={formValues.propellerHubSerialNumber}
                                            onChange={handleChange}
                                            className="w-full"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="propeller 1 Serial Number"
                                            name="prop1SerialNumber"
                                            value={formValues.prop1SerialNumber}
                                            onChange={handleChange}
                                            className="w-full"
                                            required
                                        />
                                    </Grid>
                                    {prop1Blades > 1 && <Grid item xs={3}>
                                        <TextField
                                            label="propeller 2 Serial Number"
                                            name="prop2SerialNumber"
                                            value={formValues.prop2SerialNumber}
                                            onChange={handleChange}
                                            className="w-full"
                                        />
                                    </Grid>}
                                    {prop1Blades > 2 && <Grid item xs={3}>
                                        <TextField
                                            label="propeller 3 Serial Number"
                                            name="prop3SerialNumber"
                                            value={formValues.prop3SerialNumber}
                                            onChange={handleChange}
                                            className="w-full"
                                        />
                                    </Grid>}
                                    {prop1Blades > 3 && <Grid item xs={3}>
                                        <TextField
                                            label="propeller 4 Serial Number"
                                            name="prop4SerialNumber"
                                            value={formValues.prop4SerialNumber}
                                            onChange={handleChange}
                                            className="w-full"
                                        />
                                    </Grid>}
                                    {prop1Blades > 4 && <Grid item xs={3}>
                                        <TextField
                                            label="propeller 5 Serial Number"
                                            name="prop5SerialNumber"
                                            value={formValues.prop5SerialNumber}
                                            onChange={handleChange}
                                            className="w-full"
                                        />
                                    </Grid>}
                                    {prop1Blades < 5 && <Grid item xs={3}>
                                        <div
                                            onClick={() => {
                                                setProp1Blades(prop1Blades + 1)

                                            }}
                                            className="inline-block px-2 py-1 font-semibold rounded-md cursor-pointer border-2 border-dashed border-[#4995CF] text-[#4995CF]"
                                        >
                                            + Add S/N
                                        </div>
                                    </Grid>}

                                    <Grid item xs={12}>
                                        <Collapsible isOpen={formValues.dualEngine}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <p className='font-bold mt-3'>
                                                        Engine 2 and Propeller
                                                    </p>
                                                </Grid>

                                                <Grid item xs={3}>
                                                    <SearchableDropdown
                                                        options={{ options: make }}
                                                        label={"Make"}
                                                        value={formValues.engineMoake_engine2}
                                                        onChange={(e: string) => handleAutocompleteChange({ name: "engineMoake_engine2", value: e })}
                                                    />
                                                </Grid>

                                                <Grid item xs={3}>
                                                    <SearchableDropdown
                                                        options={{ options: model }}
                                                        label={"Model"}
                                                        value={formValues.engineModel_engine2}
                                                        onChange={(e: string) => handleAutocompleteChange({ name: "engineModel_engine2", value: e })}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        label="Engine Serial Number"
                                                        name="engineSerialNumber_engine2"
                                                        value={formValues.engineSerialNumber_engine2}
                                                        onChange={handleChange}
                                                        className="w-full"
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    {formValues.hourMeter !== "HOBBS" && <TextField
                                                        label="Current Tach"
                                                        name="currentTach_engine2"
                                                        type="number"
                                                        value={formValues.currentTach_engine2}
                                                        onChange={handleChangeNumber}
                                                        className="w-full"
                                                    />}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        label="Time since Major Overhaul"
                                                        name="tsmoh_engine2"
                                                        type="number"
                                                        value={formValues.tsmoh_engine2}
                                                        onChange={handleChangeNumber}
                                                        className="w-full"
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        type="date"
                                                        name="engineLastAnnualDate_engine2"
                                                        value={formValues.engineLastAnnualDate_engine2}
                                                        onChange={handleChange}
                                                        label="Last Annual Inspection Date"
                                                        className="w-full "
                                                        InputProps={{ inputProps: { max: new Date().toISOString().split("T")[0] } }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        type="date"
                                                        name="lastOilChangeDate_engine2"
                                                        value={formValues.lastOilChangeDate_engine2}
                                                        onChange={handleChange}
                                                        label="Last Oil Change Date"
                                                        className="w-full "
                                                        InputProps={{ inputProps: { max: new Date().toISOString().split("T")[0] } }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <TextField
                                                        label="Tach Time at Last Oil Change"
                                                        name="tachTimeAtLastOilChange_engine2"
                                                        type="number"
                                                        value={formValues.tachTimeAtLastOilChange_engine2}
                                                        onChange={handleChangeNumber}
                                                        className="w-full"
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label="Hours"
                                                                name="engineOilChangeHours_engine2"
                                                                type="number"
                                                                value={formValues.engineOilChangeHours_engine2}
                                                                onChange={handleChangeNumber}
                                                                className="w-full"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label="Days"
                                                                name="engineOilChangeDays_engine2"
                                                                type="number"
                                                                value={formValues.engineOilChangeDays_engine2}
                                                                onChange={handleChangeNumber}
                                                                className="w-full"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <p className='font-bold text-[11px]'>
                                                        {"(Recommended Oil Change Interval - Whichever comes first)"}
                                                    </p>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <SearchableDropdown
                                                        options={{ options: make }}
                                                        label={"Propeller Make"}
                                                        value={formValues.propellerMoake_engine2}
                                                        onChange={(e: string) => handleAutocompleteChange({ name: "propellerMoake_engine2", value: e })}
                                                    />
                                                </Grid>

                                                <Grid item xs={3}>
                                                    <SearchableDropdown
                                                        options={{ options: model }}
                                                        label={"Propeller Model"}
                                                        value={formValues.propellerModel_engine2}
                                                        onChange={(e: string) => handleAutocompleteChange({ name: "propellerModel_engine2", value: e })}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        label="propeller Hub Serial Number"
                                                        name="propellerHubSerialNumber_engine2"
                                                        value={formValues.propellerHubSerialNumber_engine2}
                                                        onChange={handleChange}
                                                        className="w-full"
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        label="propeller 1 Serial Number"
                                                        name="prop1SerialNumber_engine2"
                                                        value={formValues.prop1SerialNumber_engine2}
                                                        onChange={handleChange}
                                                        className="w-full"
                                                    />
                                                </Grid>
                                                {prop2Blades > 1 && <Grid item xs={3}>
                                                    <TextField
                                                        label="propeller 2 Serial Number"
                                                        name="prop2SerialNumber_engine2"
                                                        value={formValues.prop2SerialNumber_engine2}
                                                        onChange={handleChange}
                                                        className="w-full"
                                                    />
                                                </Grid>}
                                                {prop2Blades > 2 && <Grid item xs={3}>
                                                    <TextField
                                                        label="propeller 3 Serial Number"
                                                        name="prop3SerialNumber_engine2"
                                                        value={formValues.prop3SerialNumber_engine2}
                                                        onChange={handleChange}
                                                        className="w-full"
                                                    />
                                                </Grid>}
                                                {prop2Blades > 3 && <Grid item xs={3}>
                                                    <TextField
                                                        label="propeller 4 Serial Number"
                                                        name="prop4SerialNumber_engine2"
                                                        value={formValues.prop4SerialNumber_engine2}
                                                        onChange={handleChange}
                                                        className="w-full"
                                                    />
                                                </Grid>}
                                                {prop2Blades > 4 && <Grid item xs={3}>
                                                    <TextField
                                                        label="propeller 5 Serial Number"
                                                        name="prop5SerialNumber_engine2"
                                                        value={formValues.prop5SerialNumber_engine2}
                                                        onChange={handleChange}
                                                        className="w-full"
                                                    />
                                                </Grid>}
                                                {prop2Blades < 5 && <Grid item xs={3}>
                                                    <div
                                                        onClick={() => {
                                                            setProp2Blades(prop2Blades + 1)

                                                        }}
                                                        className="inline-block px-2 py-1 font-semibold rounded-md cursor-pointer border-2 border-dashed border-[#4995CF] text-[#4995CF]"
                                                    >
                                                        + Add S/N
                                                    </div>
                                                </Grid>}
                                            </Grid>

                                        </Collapsible>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <p className='font-bold mt-3'>
                                            Owner
                                        </p>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            label="Owner Name"
                                            name="ownerName"
                                            value={formValues.ownerName}
                                            onChange={handleChange}
                                            className="w-full"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Owner Phone"
                                            name="ownerPhone"
                                            value={formValues.ownerPhone}
                                            onChange={handleChange}
                                            className="w-full"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Owner Email"
                                            name="ownerEmail"
                                            value={formValues.ownerEmail}
                                            onChange={handleChange}
                                            className="w-full"
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FileUploadWidget
                                            id="aircraft-image"
                                            label={"Upload Aircraft Image"}
                                            value={formValues.image}
                                            onChange={(e: string) => handleAutocompleteChange({ name: "image", value: e })}
                                        />
                                    </Grid>

                                    {prop.data?.id && <Grid item xs={12}>
                                        {/* <div className="max-h-[150px] overflow-scroll"> */}
                                            <AlbumManage aircraftId={prop.data?.id} />
                                        {/* </div> */}
                                    </Grid>}
                                </Grid>

                                <div className="mt-5 flex gap-3">
                                    <CustomButtonFill text='Submit' onClick={() => { }} />
                                    {prop.permissionPower >= 5 && <Tooltip title="Delete Aircraft" arrow>
                                        <DeleteIcon className="mt-2 text-[red] cursor-pointer" onClick={() => { setOpenDeleteModal(true) }} />
                                    </Tooltip>}
                                </div>
                            </Box>
                        </div>
                    </div>

                </Card>
            </Modal>}

            {showEditConfirmation && <EditAircraftConfirmation onDecide={handleSubmitUpdate} />}

            {openDeleteModal && <DeleteAircraft onClose={() => {
                setOpenDeleteModal(false)
                prop.onClose(false)
            }} id={prop.data?.id} />}
        </>
    )
}

const EditAircraftConfirmation = ({ onDecide }: { onDecide: (isContinue: boolean) => void }) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    return <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card className='' sx={style}>
            <div className='flex  flex-row justify-between'>
                <h1 className="text-3xl mb-5">WARNING - AIRCRAFT DATA CHANGES</h1>
                <CloseIcon className='hover:cursor-pointer' onClick={() => onDecide(false)}></CloseIcon>
            </div>
            <p>
                You are about to make changes to critical aircraft data. Please note that modifying information such as the Tachometer Time does not automatically update associated values like the Airframe Total Time (TT) or Time Since Major Overhaul (TSMO). These discrepancies could adversely affect the accuracy of logbook entries and maintenance records.
            </p>

            <p className='pt-3'>By proceeding, you accept responsibility for ensuring all related data is correctly updated and maintained.</p>

            <p className='pt-3'>Do you wish to continue with these changes?</p>

            <div className="flex mt-5 gap-3">
                <CustomButtonFill text='Confirm' onClick={async () => {
                    onDecide(true)
                }} />
                <CustomButton text="Cancel" onClick={() => onDecide(false)} />
            </div>

        </Card>
    </Modal>
}

const DeleteAircraft = ({ id, onClose }: { id: string, onClose: () => void }) => {
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    return <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card className='' sx={style}>
            <div className='flex  flex-row justify-between'>
                <h1 className="text-3xl mb-5">Delete Aircraft</h1>
                <CloseIcon className='hover:cursor-pointer' onClick={() => onClose()}></CloseIcon>
            </div>
            <h3>
                This can not be undone. You will lose all data! Are you sure?
            </h3>

            <div className="flex mt-5 gap-3">
                <CustomButtonFill text='Yes' onClick={async () => {
                    showOverlay()
                    const db = getFirestore()
                    await deleteDoc(doc(db, AircraftCollection, id));
                    onClose()
                    hideOverlay()
                }} />
                <CustomButton text="No" onClick={() => onClose()} />
            </div>

        </Card>
    </Modal>
}


// export const AddAircraftDeprecated = (prop: { onClose: (val: boolean) => void, data: any, isEditMode: boolean }) => {
//     const db = getFirestore()
//     const { showOverlay, hideOverlay } = useLoadingOverlay();
//     const [showEditConfirmation, setshowEditConfirmation] = useState(false)
//     const [formData, setFormData] = useState(prop.data)
//     const [formDataUpdate, setFormDataUpdate] = useState({})

//     const style = {
//         position: 'absolute' as 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         bgcolor: 'background.paper',
//         p: 3,
//     };

//     const classes = ['col-span-2', 'col-span-2', 'col-span-3', 'col-span-3', 'col-span-2',
//         'col-span-3', 'col-span-3', 'col-span-2', 'col-span-2', 'col-span-2',
//         'col-span-3', 'col-span-3', 'col-span-2', 'col-span-2', 'col-span-2',


//         'col-span-3', 'col-span-3', 'col-span-3', 'col-span-3',
//         'col-span-3', 'col-span-3', 'col-span-3', 'col-span-3',

//         'col-span-3', 'col-span-3', 'col-span-6',
//         'col-span-3', 'col-span-3', 'col-span-3', 'col-span-3',

//         'col-span-4', 'col-span-4', 'col-span-4',
//         'col-span-12'];

//     const schema: RJSFSchema = {
//         type: 'object',
//         properties: {
//             aircraftId: {
//                 type: 'string',
//                 title: "Aircraft Id",
//             },
//             year: {
//                 type: 'number',
//                 title: "Year",
//             },
//             "make": {
//                 type: "string",
//                 title: "Make",
//                 // enum: make
//             },
//             "model": {
//                 type: "string",
//                 title: "Model",
//                 // enum: model
//             },
//             "aircraftSerialNumber": {
//                 type: "string",
//                 title: "Aircraft Serial Number",
//             },
//             "engineMoake": {
//                 type: "string",
//                 title: "Engine Make",
//             },
//             "engineModel": {
//                 type: "string",
//                 title: "Engine Model",
//             },
//             "engineSerialNumber": {
//                 type: "string",
//                 title: "Engine Serial Number",
//             },
//             "currentTach": {
//                 type: "number",
//                 title: "Current Tach",
//             },
//             "tsmoh": {
//                 type: "number",
//                 title: "Time since Major Overhaul",
//             },
//             "engineMoake_engine2": {
//                 type: "string",
//                 title: "Engine 2 Make(if applicable)",
//             },
//             "engineModel_engine2": {
//                 type: "string",
//                 title: "Engine 2 Model(if applicable)",
//             },
//             "engineSerialNumber_engine2": {
//                 type: "string",
//                 title: "Engine 2 S/N(if applicable)",
//             },
//             "currentTach_engine2": {
//                 type: "number",
//                 title: "Current Tach(if applicable)",
//             },
//             "tsmoh_engine2": {
//                 type: "number",
//                 title: "Time since Major Overhaul(if applicable)",
//             },
//             "propellerMoake": {
//                 type: "string",
//                 title: "Propeller Make",
//             },
//             "propellerModel": {
//                 type: "string",
//                 title: "Propeller Model",
//             },
//             "propellerMoake_engine2": {
//                 type: "string",
//                 title: "Propeller 2 Make(if applicable)",
//             },
//             "propellerModel_engine2": {
//                 type: "string",
//                 title: "Propeller 2 Model(if applicable)",
//             },
//             "propellerHubSerialNumber": {
//                 type: "string",
//                 title: "Propeller Hub Serial Number",
//             }, "prop1SerialNumber": {
//                 type: "string",
//                 title: "Prop 1 S/N",
//             }, "prop2SerialNumber": {
//                 type: "string",
//                 title: "Prop 2 S/N(if applicable)",
//             }, "prop3SerialNumber": {
//                 type: "string",
//                 title: "Prop 3 S/N(if applicable)",
//             }, "prop4SerialNumber": {
//                 type: "string",
//                 title: "Prop 4 S/N(if applicable)",
//             }, "prop5SerialNumber": {
//                 type: "string",
//                 title: "Prop 5 S/N(if applicable)",
//             },
//             hide: {},
//             "totalTime": {
//                 type: "number",
//                 title: "Total Time",
//             },
//             "aircraftCategory": {
//                 type: "string",
//                 title: "Aircraft Category",
//                 enum: aircraftCategory.map(item => item.value)
//             },
//             "lastAnnualDate": {
//                 type: "string",
//                 format: "date",
//                 title: "Last Annual Date",
//             },
//             "lastOilChangedDateAndTachTime": {
//                 type: "string",
//                 title: "Last Oil changed Date And Tach Time",
//             },
//             ownerName: {
//                 type: 'string',
//                 title: "Owner Name",
//             },
//             ownerPhone: {
//                 type: 'string',
//                 title: "Owner Phone",
//             },
//             ownerEmail: {
//                 type: 'string',
//                 title: "Owner Email",
//             },
//             image: { type: "string", title: "Upload Image" }
//         },
//         "required": [
//             "aircraftId", "year", "make", "model", "aircraftSerialNumber", "engineMoake", "engineModel",
//             "engineSerialNumber", "propellerMoake", "propellerModel", "propellerHubSerialNumber", "prop1SerialNumber", "currentTach", "totalTime",
//             "tsmoh", "lastAnnualDate", "lastOilChangedDateAndTachTime", "ownerName", "ownerPhone", "ownerEmail", "aircraftCategory"
//         ]
//     };

//     const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
//         return (
//             <div className='grid grid-cols-12 gap-4'>
//                 {props.properties.map((element, index) => (
//                     <div key={index} className={classes[index]}>{element.content}</div>
//                 ))}
//             </div>
//         );
//     }

//     // const inputStyles = "bg-[red] border rounded-[]"

//     const uiSchema: UiSchema = {
//         'ui:ObjectFieldTemplate': ObjectFieldTemplate,
//         aircraftId: {
//             "ui:description": (
//                 <p className='font-bold mt-3'>
//                     Engine 1
//                 </p>
//             ),
//         },
//         image: {
//             "ui:widget": "fileUpload"
//         },
//         make: {
//             "ui:widget": SearchableDropdown,
//             'ui:options': {
//                 options: make,
//             },
//         },
//         model: {
//             "ui:widget": SearchableDropdown,
//             'ui:options': {
//                 options: model,
//             },
//         },
//         engineMoake: {
//             "ui:widget": SearchableDropdown,
//             'ui:options': {
//                 options: make,
//             },
//             "ui:description": (
//                 <p className='font-bold mt-3'>
//                     Engine 2 (if applicable)
//                 </p>
//             ),
//         },
//         engineModel: {
//             "ui:widget": SearchableDropdown,
//             'ui:options': {
//                 options: model,
//             },
//         },
//         engineMoake_engine2: {
//             "ui:widget": SearchableDropdown,
//             'ui:options': {
//                 options: make,
//             },
//             "ui:description": (
//                 <p className='font-bold mt-3'>
//                     Propeller
//                 </p>
//             ),
//         },
//         engineModel_engine2: {
//             "ui:widget": SearchableDropdown,
//             'ui:options': {
//                 options: model,
//             },
//         },
//         propellerMoake: {
//             "ui:widget": SearchableDropdown,
//             'ui:options': {
//                 options: make,
//             },
//         },
//         propellerModel: {
//             "ui:widget": SearchableDropdown,
//             'ui:options': {
//                 options: model,
//             },
//         },

//         propellerMoake_engine2: {
//             "ui:widget": SearchableDropdown,
//             'ui:options': {
//                 options: make,
//             },
//         },
//         propellerModel_engine2: {
//             "ui:widget": SearchableDropdown,
//             'ui:options': {
//                 options: model,
//             },
//         }
//     };

//     const widgets = {
//         fileUpload: FileUploadWidget,
//         // CustomDropdown: (props: any) => <CustomDropdown {...props} options={make} />
//     };

//     const handleSubmit = async (isContinue: boolean) => {
//         setshowEditConfirmation(false)
//         if (isContinue) {
//             showOverlay()
//             await setDoc(doc(db, AircraftCollection, prop.data.id), formData)
//             prop.onClose(false);
//             hideOverlay()
//         } else {

//         }
//     }

//     return (
//         <>
//             {<Modal
//                 open={true}
//                 aria-labelledby="modal-modal-title"
//                 aria-describedby="modal-modal-description"
//             >
//                 <Card className='signup-card w-[90vw] ' sx={style}>
//                     <div className='flex  flex-row justify-between'>
//                         <h1 className="text-3xl mb-5">{prop.isEditMode ? "Edit" : "Add"} Aircraft</h1>
//                         <CloseIcon className='hover:cursor-pointer' onClick={() => prop.onClose(false)}></CloseIcon>
//                     </div>
//                     <div className='h-[80vh] overflow-scroll px-3 pt-2'>
//                         <Form schema={schema} validator={validator} uiSchema={uiSchema} widgets={widgets} formData={formData}
//                             onSubmit={async (data) => {

//                                 const aircrft = { ...data.formData, addedUser: localStorage.getItem(userKey) }

//                                 // edit
//                                 if (prop.data.id) {
//                                     setFormData(aircrft)
//                                     setshowEditConfirmation(true)
//                                     // await setDoc(doc(db, AircraftCollection, aircrft.aircraftId), aircrft)
//                                     // prop.onClose(false);
//                                     // hideOverlay()
//                                 }
//                                 //create
//                                 else {
//                                     showOverlay()
//                                     const data: any[] = [];

//                                     const q = query(collection(db, AircraftCollection), where("aircraftId", "==", aircrft.aircraftId));
//                                     const querySnapshot = await getDocs(q);
//                                     querySnapshot.forEach((doc) => {
//                                         data.push(doc.data());
//                                     });
//                                     // const docRef = doc(db, AircraftCollection, aircrft.aircraftId);
//                                     // const docSnap = await getDoc(docRef);

//                                     if (data.length > 0) {
//                                         alert(`Aircraft with id: ${aircrft.aircraftId} already exist!`)
//                                         hideOverlay()
//                                     } else {
//                                         // console.log(data)
//                                         await addDoc(collection(db, AircraftCollection), aircrft);
//                                         // await setDoc(doc(db, AircraftCollection, aircrft.aircraftId), aircrft)
//                                         hideOverlay()
//                                         prop.onClose(false);
//                                     }
//                                 }
//                             }} >
//                             <div className='mt-3'>
//                                 <CustomButtonFill text='Submit' onClick={() => { }} />
//                             </div>


//                         </Form>
//                     </div>

//                 </Card>
//             </Modal>}

//             {showEditConfirmation && <EditAircraftConfirmation onDecide={handleSubmit} />}
//         </>
//     )
// }
