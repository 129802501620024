import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { CustomButton } from "../components/button"
import { getAuth, sendEmailVerification } from "firebase/auth";
import { CustomButtonFill } from "../components/buttonfill";
import { useState } from "react";

export const VerifyEmailDialog = () => {
    const auth = getAuth();
    const [sent, setSent] = useState(false)
    const [err, setErr] = useState<string>()

    return <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="p-3"
    >
        <DialogTitle id="alert-dialog-title">
            {"Great! Welcome to Airlogbooks.com!"}
        </DialogTitle>
        <DialogContent>
            {sent && <Alert className="mb-3" severity="info">{"Verification email sent!"}</Alert>}
            {err && <Alert className="mb-3" severity="error">{err}</Alert>}
            <DialogContentText id="alert-dialog-description">
                Please check your email and verify email address.
            </DialogContentText>
        </DialogContent>
        <DialogActions>

            <CustomButtonFill onClick={async () => {
                try {
                    await sendEmailVerification(auth.currentUser!);
                    setSent(true)
                    setErr(undefined)
                    // alert("Verification email sent!");
                } catch (error: any) {
                    if (error.code === "auth/too-many-requests") {
                        setSent(false)
                        setErr("Too many requests! Please wait before trying again.");
                    } else {
                        setSent(false)
                        setErr("Failed to send verification email.");
                    }
                }
            }} text="Resend Email" />
            <CustomButton onClick={() => {
                window.location.reload()
            }} text="Done and Refresh" />
        </DialogActions>
    </Dialog>
}