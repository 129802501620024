import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getSubscription_firebase } from "../services/subscriptions";
import { CustomerSubscription } from "../models/subscription";
import { queryKeyUserSubscription } from "../keys";

// export const useSubscriptionById = (customerId: string) => {
//     const queryClient = useQueryClient()
//     const query = useQuery<CustomerSubscription | null>({
//         queryKey: [queryKeyUserSubscription, customerId], queryFn: () => {
//             if (customerId)
//                 return getSubscription_firebase(customerId)

//             return null
//         }
//     })

//     const invalidateSubscription = () => {
//         queryClient.invalidateQueries({ queryKey: [queryKeyUserSubscription, customerId] });
//     };

//     return {
//         ...query,
//         refreshSubscription: invalidateSubscription,
//     };
// };

export const useSubscriptionById = (email: string) => {
    const queryClient = useQueryClient()

    const query = useQuery<CustomerSubscription | null>({
        queryKey: [queryKeyUserSubscription, email], queryFn: () => {
            if (email)
                return getSubscription_firebase(email)

            return null
        }
    })

    const invalidateSubscription = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyUserSubscription, email] });
    };

    return {
        ...query,
        refreshSubscription: invalidateSubscription,
    };
};