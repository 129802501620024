import { IconButton, TextField, Tooltip } from '@mui/material';
import { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const PublicAccessLink = ({ url, text }: { url: string, text: string }) => {
    const [copied, setCopied] = useState(false);


    const handleCopy = () => {
        navigator.clipboard.writeText(url);
        setCopied(true);
        setTimeout(() => setCopied(false), 3000); // Reset the copied state after 2 seconds
    };

    return (
        <div className="flex flex-col items-center justify-center p-3 ">
            <p className="text-sm font-medium text-gray-700 mb-1">
                {text}:
            </p>
            <div className="flex items-center space-x-2">
                <TextField
                    value={url}
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    className="w-72"
                />
                <Tooltip title="Copy to clipboard">
                    <IconButton onClick={handleCopy} color="primary">
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>
            </div>
            {copied && (
                <p className="mt-2 text-sm text-green-600">
                    Link copied to clipboard!
                </p>
            )}
        </div>
    );
};