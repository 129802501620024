import { collection, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import { AircraftPublicDetailsModel, AircraftMxLogAttachmentsModel, PilotLogAttachmentsModel } from "../models/logAttachments";
import { AircraftPublicDetailsCollection, AircraftMxLogAttachmentsCollection, PilotLogAttachmentsCollection } from "../keys";

export const getAllAircraftMXAttachments_firebase = async (aircraftId: string): Promise<AircraftMxLogAttachmentsModel | null> => {
    const db = getFirestore()
    const docRef = doc(db, AircraftMxLogAttachmentsCollection, aircraftId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return ({ ...docSnap.data(), id: docSnap.id }) as AircraftMxLogAttachmentsModel
    }

    return null;
}

export const getAllAircraftImages_firebase = async (aircraftId: string): Promise<AircraftPublicDetailsModel | null> => {
    const db = getFirestore()
    const docRef = doc(db, AircraftPublicDetailsCollection, aircraftId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return ({ ...docSnap.data(), id: docSnap.id }) as AircraftPublicDetailsModel
    }

    return null;
}

export const getPilotLogAttachments_firebase = async (pilotId: string): Promise<PilotLogAttachmentsModel | null> => {
    const db = getFirestore()
    const docRef = doc(db, PilotLogAttachmentsCollection, pilotId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return ({ ...docSnap.data(), id: docSnap.id }) as PilotLogAttachmentsModel
    }

    return null;
}