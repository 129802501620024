import { useState, useEffect, useRef } from "react";
import { Grid, Card, IconButton, Modal, Dialog, DialogContent, Button, useTheme, Box, MobileStepper, DialogTitle, ImageList, ImageListItem } from "@mui/material";
import { ZoomIn, ZoomOut, Close, KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft, X } from "@mui/icons-material";
import { FirebaseImage } from "./firebaseImage";
import { cn } from "../userData/userData";
import { useAllAircraftImages } from "../hooks/useAttachments";
import { KeyboardArrowLeft, KeyboardArrowRight, ZoomOutMap } from '@mui/icons-material';
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { CircularProgress } from '@mui/material';

// export const AlbumView = ({ aircraftId }: { aircraftId: string }) => {
//     const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
//     const [isZoomed, setIsZoomed] = useState(false);

//     const { data: imageModel } = useAllAircraftImages(aircraftId);
//     const images = imageModel?.images ?? [];

//     const handleImageClick = (index: number) => {
//         setSelectedIndex(index);
//         setIsZoomed(false);
//     };

//     const handleCloseModal = () => {
//         setSelectedIndex(null);
//         setIsZoomed(false);
//     };

//     const toggleZoom = () => {
//         setIsZoomed(!isZoomed);
//     };

//     const handlePrev = () => {
//         if (selectedIndex !== null && selectedIndex > 0) {
//             setSelectedIndex(selectedIndex - 1);
//         }
//     };

//     const handleNext = () => {
//         if (selectedIndex !== null && selectedIndex < images.length - 1) {
//             setSelectedIndex(selectedIndex + 1);
//         }
//     };

//     // Handle keyboard navigation
//     useEffect(() => {
//         const handleKeyDown = (event: KeyboardEvent) => {
//             if (selectedIndex !== null) {
//                 if (event.key === "ArrowLeft") handlePrev();
//                 if (event.key === "ArrowRight") handleNext();
//                 if (event.key === "Escape") handleCloseModal();
//             }
//         };
//         window.addEventListener("keydown", handleKeyDown);
//         return () => window.removeEventListener("keydown", handleKeyDown);
//     }, [selectedIndex]);

//     return (
//         <div className="container py-4">
//             <Grid container spacing={2}>
//                 {images.map((image, index) => (
//                     <Grid item xs={12} sm={6} md={4} lg={3} key={image}>
//                         <Card
//                             className="cursor-pointer transition-transform hover:scale-105"
//                             onClick={() => handleImageClick(index)}
//                         >
//                             <FirebaseImage
//                                 img={image}
//                                 classes={cn("w-full h-[160px] object-cover rounded-lg cursor-pointer hover:scale-105 transition-transform ")} />
//                         </Card>
//                     </Grid>
//                 ))}
//             </Grid>

//             <Modal open={selectedIndex !== null} onClose={handleCloseModal} className="flex items-center justify-center">
//                 <div className="bg-white p-4 rounded-lg max-w-4xl w-full mx-4 relative flex flex-col items-center shadow-lg">
//                     <div className="flex justify-between w-full">
//                         <h1 className="text-lg font-semibold">Image Preview</h1>
//                         <IconButton onClick={handleCloseModal} size="small">
//                             <Close />
//                         </IconButton>
//                     </div>

//                     <div className={`overflow-hidden ${isZoomed ? "cursor-zoom-out" : "cursor-zoom-in"}`}>
//                         <div onClick={toggleZoom} className="flex flex-col items-center">
//                             {selectedIndex !== null && (
//                                 <FirebaseImage
//                                     img={images[selectedIndex]}
//                                     classes={`w-full max-h-[80vh] transition-transform duration-300 ${isZoomed ? "scale-125" : "scale-100"}`}
//                                 />
//                             )}
//                         </div>
//                     </div>

//                     <div className="flex justify-center gap-2 mt-3">
//                         <IconButton onClick={handlePrev} disabled={selectedIndex === 0} size="small">
//                             <KeyboardDoubleArrowLeft />
//                         </IconButton>
//                         <IconButton onClick={handleNext} disabled={selectedIndex === images.length - 1} size="small">
//                             <KeyboardDoubleArrowRight />
//                         </IconButton>
//                         <IconButton onClick={toggleZoom} size="small">
//                             {isZoomed ? <ZoomOut /> : <ZoomIn />}
//                         </IconButton>
//                     </div>
//                 </div>
//             </Modal>
//         </div>
//     );
// };


export const AlbumView = ({ aircraftId }: { aircraftId: string }) => {
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [isZoomed, setIsZoomed] = useState(false);

    const { data: imageModel } = useAllAircraftImages(aircraftId);
    const images = imageModel?.images ?? [];
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    const handleImageClick = (index: number) => {
        setSelectedIndex(index);
        setIsZoomed(false);
    };

    const handleCloseModal = () => {
        setSelectedIndex(null);
        setIsZoomed(false);
    };

    const toggleZoom = () => {
        setIsZoomed(!isZoomed);
    };

    const handlePrev = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: -220, // Adjust this value based on image width and spacing
                behavior: 'smooth',
            });
        }
    };

    const handleNext = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: 220, // Adjust this value based on image width and spacing
                behavior: 'smooth',
            });
        }
    };


    const handlePrevSelected = () => {
        if (selectedIndex !== null && selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
        }
    };

    const handleNextSelected = () => {
        if (selectedIndex !== null && selectedIndex < images.length - 1) {
            setSelectedIndex(selectedIndex + 1);
        }
    };

    // Handle keyboard navigation
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (selectedIndex !== null) {
                if (event.key === "ArrowLeft") handlePrevSelected();
                if (event.key === "ArrowRight") handleNextSelected();
                if (event.key === "Escape") handleCloseModal();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [selectedIndex]);

    useEffect(() => {
        const checkOverflow = () => {
            if (scrollContainerRef.current) {
                const container = scrollContainerRef.current;
                setIsOverflowing(container.scrollWidth > container.clientWidth);  // Compare scroll width and client width
            }
        };

        checkOverflow(); // Initial check
        window.addEventListener("resize", checkOverflow); // Re-check on window resize

        return () => {
            window.removeEventListener("resize", checkOverflow); // Clean up on unmount
        };
    }, []);


    return (
        <div className="container">
            <div className="relative">
                <div
                    ref={scrollContainerRef}
                    className="flex overflow-x-auto space-x-4 py-2"
                >

                    {images.map((image, index) => (
                        <div key={image} className="flex-shrink-0">
                            <Card
                                className="cursor-pointer transition-transform hover:scale-105"
                                onClick={() => handleImageClick(index)}
                            >
                                <FirebaseImage
                                    img={image}
                                    classes="w-[300px] md:w-[450px] h-[200px] md:h-[250px] object-cover rounded-lg cursor-pointer hover:scale-105 transition-transform"
                                />
                            </Card>
                        </div>
                    ))}
                </div>

                {/* Arrows for Horizontal Scroll */}
                <div className="pt-5 flex flex-row justify-center ">
                    {isOverflowing && (
                        <>
                            <IconButton
                                onClick={handlePrev}
                                disabled={selectedIndex === 0}
                                size="small"
                                className="absolute top-1/2 left-0 transform -translate-y-1/2 z-10"
                            >
                                <KeyboardDoubleArrowLeft />
                            </IconButton>
                            <IconButton
                                onClick={handleNext}
                                disabled={selectedIndex === images.length - 1}
                                size="small"
                                className="absolute top-1/2 right-0 transform -translate-y-1/2 z-10"
                            >
                                <KeyboardDoubleArrowRight />
                            </IconButton>
                        </>
                    )}
                </div>

            </div>

            {/* Modal for Image Preview */}
            <Modal open={selectedIndex !== null} onClose={handleCloseModal} className="flex items-center justify-center">
                <div className="bg-white p-4 rounded-lg max-w-4xl w-full mx-4 relative flex flex-col items-center shadow-lg">
                    <div className="flex justify-between w-full">
                        <h1 className="text-lg font-semibold">Image Preview</h1>
                        <IconButton onClick={handleCloseModal} size="small">
                            <Close />
                        </IconButton>
                    </div>

                    <div className={`overflow-hidden ${isZoomed ? "cursor-zoom-out" : "cursor-zoom-in"}`}>
                        <div onClick={toggleZoom} className="flex flex-col items-center">
                            {selectedIndex !== null && (
                                <FirebaseImage
                                    img={images[selectedIndex]}
                                    classes={`w-full max-h-[80vh] transition-transform duration-300 ${isZoomed ? "scale-125" : "scale-100"}`}
                                />
                            )}
                        </div>
                    </div>

                    <div className="flex justify-center gap-2 mt-3">
                        <IconButton onClick={handlePrevSelected} disabled={selectedIndex === 0} size="small">
                            <KeyboardDoubleArrowLeft />
                        </IconButton>
                        <IconButton onClick={handleNextSelected} disabled={selectedIndex === images.length - 1} size="small">
                            <KeyboardDoubleArrowRight />
                        </IconButton>
                        <IconButton onClick={toggleZoom} size="small">
                            {isZoomed ? <ZoomOut /> : <ZoomIn />}
                        </IconButton>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

// export const AlbumView = ({ aircraftId }: { aircraftId: string }) => {
//     const { data: imageModel } = useAllAircraftImages(aircraftId);
//     const images = imageModel?.images ?? [];

//     return (
//         <div className="container">
//             <ImageGallery images={images} autoPlay={true} />
//         </div>
//     );
// };

// interface CarouselProps {
//     images: string[];
//     autoPlay?: boolean;
//     autoPlayInterval?: number;
//     height?: number | string;
//     animationDuration?: number
// }

// const ImageGallery: React.FC<CarouselProps> = ({
//     images,
//     autoPlay = false,
//     autoPlayInterval = 3000,
//     height = 400,
//     animationDuration = 500,
// }) => {
//     const [activeStep, setActiveStep] = useState(0);
//     const maxSteps = images.length;
//     const timerRef = useRef<NodeJS.Timeout | null>(null);
//     const imageRef = useRef<HTMLImageElement>(null);
//     const [openGallery, setOpenGallery] = useState(false);

//     useEffect(() => {
//         if (autoPlay) {
//             startAutoplay();
//             return () => stopAutoplay();
//         }
//         return () => stopAutoplay();
//     }, [autoPlay, images]);

//     const handleNext = () => {
//         setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
//         resetAutoplay();
//     };

//     const handleBack = () => {
//         setActiveStep((prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps);
//         resetAutoplay();
//     };

//     const handleStepChange = (step: number) => {
//         setActiveStep(step);
//         resetAutoplay();
//     };

//     const startAutoplay = () => {
//         timerRef.current = setInterval(() => {
//             setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
//         }, autoPlayInterval);
//     };

//     const stopAutoplay = () => {
//         if (timerRef.current) {
//             clearInterval(timerRef.current);
//             timerRef.current = null;
//         }
//     };

//     const resetAutoplay = () => {
//         stopAutoplay();
//         if (autoPlay) {
//             startAutoplay();
//         }
//     };

//     const handleOpenGallery = () => {
//         setOpenGallery(true);
//     };

//     const handleCloseGallery = () => {
//         setOpenGallery(false);
//     };

//     return (
//         <Box sx={{ maxWidth: '100%', flexGrow: 1, position: 'relative', height: height, overflow: 'hidden' }}>
//             {images.length > 0 && (
//                 // <img
//                 //     ref={imageRef}
//                 //     src={images[activeStep]}
//                 //     alt={`Image ${activeStep + 1}`}
//                 //     className="object-cover w-full h-full"
//                 //     style={{
//                 //         transition: `opacity ${animationDuration}ms ease-in-out`, // Use animationDuration prop
//                 //         opacity: 1, // Start with opacity 1
//                 //     }}
//                 //     onLoad={() => {
//                 //         if (imageRef.current) {
//                 //             imageRef.current.style.opacity = '1'; // Ensure opacity is 1 after image loads
//                 //         }
//                 //     }}
//                 // />\
//                 <FirebaseCustomImage img={images[activeStep]} classes="" />
//             )}
//             {/* ... (rest of the carousel code remains the same) */}
//             <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//                 <IconButton onClick={handleBack} disabled={activeStep === 0} aria-label="previous" sx={{ color: 'white' }}>
//                     <KeyboardArrowLeft />
//                 </IconButton>

//                 <div className="flex">
//                     <MobileStepper
//                         steps={maxSteps}
//                         position="static"
//                         activeStep={activeStep}
//                         // onStepChange={handleStepChange}
//                         backButton={<KeyboardArrowLeft />}
//                         nextButton={<KeyboardArrowRight />}
//                         sx={{ backgroundColor: 'transparent', color: 'white', '& .MuiMobileStepper-dot': { backgroundColor: 'white' } }}
//                     />
//                     <IconButton onClick={handleOpenGallery} aria-label="view all" sx={{ color: 'white' }}>
//                         <ZoomOutMap /> {/* View All Icon */}
//                     </IconButton>
//                 </div>

//                 <IconButton onClick={handleNext} disabled={activeStep === maxSteps - 1} aria-label="next" sx={{ color: 'white' }}>
//                     <KeyboardArrowRight />
//                 </IconButton>
//             </Box>

//             <Dialog open={openGallery} onClose={handleCloseGallery} maxWidth="md" fullWidth>
//                 <DialogTitle>Image Gallery</DialogTitle>
//                 <DialogContent>
//                     <ImageList rowHeight={200} cols={3} gap={8}> {/* Adjust cols and rowHeight as needed */}
//                         {images.map((image, index) => (
//                             <ImageListItem key={index}>
//                                 <img
//                                     src={image}
//                                     alt={`Image ${index + 1}`}
//                                     loading="lazy" // Improve performance
//                                     style={{ cursor: 'pointer' }} // Indicate clickability
//                                     onClick={() => {
//                                         setActiveStep(index); // Set active step in main carousel
//                                         handleCloseGallery(); // Close the gallery
//                                     }}
//                                 />
//                             </ImageListItem>
//                         ))}
//                     </ImageList>
//                 </DialogContent>
//             </Dialog>
//         </Box>
//     );
// };

// export const FirebaseCustomImage = ({ img, classes }: { img: string, classes: string }) => {
//     const [imageUrl, setImageUrl] = useState('');
//     const storage = getStorage();

//     useEffect(() => {
//         const fetchImageUrl = async () => {
//             try {
//                 const url = await getDownloadURL(ref(storage, img))
//                 setImageUrl(url);
//             } catch (error) {
//                 // console.error('Error fetching image URL:', error);
//             }
//         };
//         fetchImageUrl();
//     }, [img]);

//     return (
//         <div className={classes}>
//             {imageUrl ? (
//                 <img src={imageUrl} key={imageUrl} alt="Firebase Image" className='' />
//             ) : (
//                 <CircularProgress color="inherit" className='p-1 mt-1' />
//             )}
//         </div>
//     );
// };