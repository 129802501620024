import { collection, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import { CustomerSubscription } from "../models/subscription";
import { SubscriptionsCollection, SubscriptionsDetailsCollection } from "../keys";

// export const getSubscription_firebase = async (customerId: string): Promise<CustomerSubscription | null> => {
//     const db = getFirestore()

//     if (customerId) {
//         const data: CustomerSubscription[] = [];
//         const q = query(collection(db, SubscriptionsCollection), where("customerId", "==", customerId));
//         const querySnapshot = await getDocs(q);
//         querySnapshot.forEach((doc) => {
//             data.push({ ...doc.data(), id: doc.id } as CustomerSubscription);
//         });

//         return data[0];
//     }
//     return null;
// }


export const getSubscription_firebase = async (email: string): Promise<CustomerSubscription | null> => {
    const db = getFirestore()
    if (email) {
        const subscriptionDetailsDocRef = doc(db, SubscriptionsDetailsCollection, email!);
        const subscriptionDetailsDocSnap = await getDoc(subscriptionDetailsDocRef);

        if (subscriptionDetailsDocSnap.exists()) {
            const data: CustomerSubscription[] = [];
            const q = query(collection(db, SubscriptionsCollection), where("customerId", "==", subscriptionDetailsDocSnap.data().userId));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                data.push({ ...doc.data(), id: doc.id } as CustomerSubscription);
            });

            if (data.length > 0) {
                return {
                    email: subscriptionDetailsDocSnap.data().email,
                    userId: subscriptionDetailsDocSnap.data().userId,
                    noOfAircrafts: subscriptionDetailsDocSnap.data().noOfAircrafts,
                    noOfPilots: subscriptionDetailsDocSnap.data().noOfPilots,
                    isYearly: subscriptionDetailsDocSnap.data().isYearly,
                    status: data[0]?.status,
                } as CustomerSubscription
            }
            else {
                return null;
            }
        }
        else { return null }
    }
    return null;
}