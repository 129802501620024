import { Alert, Card, Link } from '@mui/material';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import './auth.css'
import { alignProperty } from '@mui/material/styles/cssUtils';
import { getAuth, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { userIdKey, userKey } from '../keys';
import Logo from '../assets/images/logo.jpeg';
import { CustomButtonFill } from '../components/buttonfill';
import { useLoadingOverlay } from '../loading';

export const SignIn = () => {
    const [error, setError] = useState<string | null>(null);
    const auth = getAuth();
    const uiSchema = {
        'ui:submitButtonOptions': {
            submitText: 'SignIn',
        },
        "email": {
            "ui:placeholder": "Enter your email"
        },
        "password": {
            "ui:widget": "password",
            "ui:placeholder": "Enter your password"
        },
    };
    const { showOverlay, hideOverlay } = useLoadingOverlay();

    const schema: RJSFSchema = {
        title: 'AirLogbooks SignIn',
        type: 'object',
        properties: {
            email: {
                type: 'string',
                "format": "email"
            },
            password: {
                type: 'string',
                "minLength": 6
            },
        },
        "required": ["email", "password"]
    };

    return (
        <div className="signup-form">
            <Card className='signup-card w-[500px]'>
                <img src={Logo} alt="Air Loggbooks" className="w-[100px] mt-[17px] m-auto" />
                {error && <Alert severity="error">{error}</Alert>}
                <Form schema={schema} validator={validator} uiSchema={uiSchema}
                    onSubmit={(data) => {
                        showOverlay()
                        signInWithEmailAndPassword(auth, data.formData.email, data.formData.password)
                            .then(async (userCredential) => {
                                if (!userCredential.user.emailVerified) {
                                    await sendEmailVerification(userCredential.user);
                                }
                                localStorage.setItem(userKey, userCredential.user.email!);
                                localStorage.setItem(userIdKey, userCredential.user.uid)
                                hideOverlay()
                            })
                            .catch((error) => {
                                hideOverlay()
                                const errorCode = error.code;
                                const errorMessage = error.message;
                                setError("Invalid email or password")
                            })
                    }} >

                    <div className=''>
                        <Link href="/forgot-password" className='mt-10'>Forgot Password?</Link>
                    </div>
                    <div className='mt-3'>
                        <CustomButtonFill text='SignIn' onClick={() => { }} />
                    </div>
                </Form>
                <h1 className='mt-3'>Don't have a account?</h1>
                <Link href="/signup" className='mt-10'>Signup</Link>
            </Card>
        </div>
    );
};
