import { useQuery, useQueryClient } from "@tanstack/react-query"
import { queryKeyUserNotifications, userKey } from "../keys"
import { NotificationModel } from "../models/notifications"
import { getAllUserNotifications_firebase } from "../services/notifications"

export const useNotifications = () => {
    const queryClient = useQueryClient()
    const userId = localStorage.getItem(userKey)!

    const query = useQuery<NotificationModel[] | null>({
        queryKey: [queryKeyUserNotifications, userId],
        queryFn: () => {
            return getAllUserNotifications_firebase(userId)
        }
    })

    const invalidateUseNotifications = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyUserNotifications, userId] });
    };

    return {
        ...query,
        refreshUserNotifications: invalidateUseNotifications,
    };
}