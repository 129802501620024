import { CircularProgress } from '@mui/material';
import imageCompression from 'browser-image-compression';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { useState, useEffect } from 'react';

export const FirebaseImage = ({ img, classes }: { img: string, classes: string }) => {
    const [imageUrl, setImageUrl] = useState('');
    const storage = getStorage();

    useEffect(() => {
        const fetchImageUrl = async () => {
            try {
                const url = await getDownloadURL(ref(storage, img))
                setImageUrl(url);
            } catch (error) {
                // console.error('Error fetching image URL:', error);
            }
        };
        fetchImageUrl();
    }, [img]);

    return (
        <div className={classes}>
            {imageUrl ? (
                <img src={imageUrl} key={imageUrl} alt="Firebase Image" className='' />
            ) : (
                <CircularProgress color="inherit" className='p-1 mt-1' />
            )}
        </div>
    );
};

export const getFileDownloadUrl = async (path: string) => {
    const storage = getStorage();
    const url = await getDownloadURL(ref(storage, path))
    return url;
}


// export const convertToBase64 = async (url: any) => {
//     const response = await fetch(url);
//     const blob = await response.blob();

//     console.log(blob.type)

//     if (!["image/jpeg", "image/png", "image/jpg"].includes(blob.type)) {
//         throw new Error(`Unsupported file type: ${blob.type}`);
//     }

//     return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onloadend = () => resolve(reader.result);
//         reader.onerror = reject;
//         reader.readAsDataURL(blob); // This ensures the Base64 prefix is included
//     });
// };

export const convertToBase64 = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const blob = await response.blob();

    if (!["image/jpeg", "image/png", "image/jpg"].includes(blob.type)) {
        throw new Error(`Unsupported file type: ${blob.type}`);
    }

    // Convert Blob to File
    const file = new File([blob], "image", { type: blob.type });

    // Compress the image
    const compressedBlob = await imageCompression(file, {
        maxSizeMB: 1, // Compress to a maximum size of 1 MB
        maxWidthOrHeight: 1024, // Resize to a maximum of 1024px in width/height
        useWebWorker: true, // Use web worker for better performance
    });

    // Convert compressed image to Base64
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = reject;
        reader.readAsDataURL(compressedBlob); // Convert compressed image to Base64
    });
};