import { useState } from 'react';
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';

export const MultiFileUploadWidget = ({ id, label, folderName, onChange, accept }:
    { id: string, label: string, folderName: string, onChange: (files: string[]) => void, accept?: string }) => {
    const [uploading, setUploading] = useState(false);
    const [fileCount, setFileCount] = useState(0); // Number of files selected
    const [error, setError] = useState(null);
    const [isDragOver, setIsDragOver] = useState(false);

    const handleFileUpload = async (event: any) => {
        const fs = getStorage();
        const files = event?.target?.files || event?.dataTransfer?.files;
        if (!files || files.length === 0) return;

        setUploading(true);
        setFileCount(files.length);
        setError(null);

        try {
            const fileNames: string[] = [];
            const uploadPromises = Array.from(files).map((file: any) => {
                const filePath = `${folderName}/${new Date().toISOString() + '___' + file.name}`;
                fileNames.push(filePath)
                const storageRef = ref(fs, filePath);
                const uploadTask = uploadBytesResumable(storageRef, file);

                return new Promise<void>((resolve, reject) => {
                    uploadTask.on(
                        'state_changed',
                        null,
                        (error) => reject(error),
                        () => resolve()
                    );
                });
            });

            await Promise.all(uploadPromises);
            // props.onChange(files);
            onChange(fileNames);
        } catch (error) {
            console.error("Error uploading files:", error);
        } finally {
            setUploading(false);
        }
    };

    const handleDragEnter = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragOver(true);
    };

    const handleDragLeave = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragOver(false);
    };

    const handleDragOver = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragOver(false);
        handleFileUpload(event);
    };

    return (
        <div
            className={`flex flex-col items-center p-4 border-2 border-dashed rounded-md cursor-pointer ${isDragOver ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <div>
                <input
                    type="file"
                    multiple
                    onChange={handleFileUpload}
                    disabled={uploading}
                    className="hidden"
                    accept={accept ?? '*'}
                    id={id}
                />

                <label htmlFor={id} className="w-full text-center cursor-pointer">
                    <p className="text-gray-600">Drag & drop files here, or click to select</p>
                    <div
                        className="mt-2 py-2 bg-[#4995CF] text-white rounded-md transition w-[200px] mx-auto"
                    >
                        {label}
                    </div>
                </label>

                {fileCount > 0 && <p className="text-green-500 mt-2">{fileCount} file(s) selected</p>}

                {uploading && <p className="text-blue-500 mt-2">Uploading files...</p>}

                {error && <p className="text-red-500 mt-2">{error}</p>}
            </div>
        </div>
    );
};
