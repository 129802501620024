import { useQuery, useQueryClient } from "@tanstack/react-query";
import { UserModel } from "../models/user";
import { queryKeyPilotsOfUser, userKey } from "../keys";
import { getAllPilots_firebase } from "../services/user";
import { usePilotPermissions } from "./usePermissions";

export const usePilots = () => {
    const queryClient = useQueryClient()
    const userId = localStorage.getItem(userKey)!
    const { data: permissions, isLoading } = usePilotPermissions(localStorage.getItem(userKey)!)

    const query = useQuery<UserModel[] | null>({
        queryKey: [queryKeyPilotsOfUser, userId],
        queryFn: () => {
            if (!permissions)
                return Promise.resolve(null);

            return getAllPilots_firebase([...permissions?.map(p => p.pilotId!) ?? []])
        },
        enabled: !!permissions,
    })

    const invalidateUserPilots = () => {
        queryClient.invalidateQueries({ queryKey: [queryKeyPilotsOfUser, userId] });
    };

    return {
        ...query,
        refreshUserPilots: invalidateUserPilots,
    };
}