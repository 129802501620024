import { Alert, Box, Card, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material"
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, query, setDoc } from "firebase/firestore"
import CloseIcon from '@mui/icons-material/Close';
import { CustomButtonFill } from "../../../components/buttonfill"
import { AircraftADCompliancesCollection, AircraftCollection, MxLogbookCollection, MxLogbookTemplateCollection, NotificationsCollection, SquawkCollection, userKey, UsersCollection } from "../../../keys"
import { useEffect, useState } from "react"
import { logBookCategory } from "../../../dropdownItems"
import { useLoadingOverlay } from "../../../loading"
import { getDateDiff } from "../../../utils/dateutils"
import { SquawksModal } from "../squawk/squawksModal";
import { CustomButton } from "../../../components/button";
import { toFixedNumber } from "../../../utils/numberUtils";
import { AddNotification } from "../notifications/addNotification";
import { addMxSignature } from "../../../services/mxSignature";
import { CustomDivider } from "../../../components/CustomDivider";
import AddLinkIcon from '@mui/icons-material/AddLink';
import { MxLogModel } from "../../../models/mx";
import { MultiFileUploadWidget } from "../../../components/multiFileUpload";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAircraftById } from "../../../hooks/useAircrafts";
import { useUserById } from "../../../hooks/useUser";
import { getAuth } from "firebase/auth";
import { MxLogPdfGeneratorModal } from "./mxLogPdfGenerator";

export const AddMxLog = (prop: {
    aircraftId: string, onClose: (val: boolean) => void, descriptionPreFill?: string, squawk?: any[], ad?: any[], notification?: any[]
}) => {
    const [formValues, setFormValues] = useState<MxLogModel>({
        date: new Date().toISOString().split("T")[0],
        ia: false,
        aAndP: false,
        owner: false,
        tachtime: 0,
        tachtime2: 0,
        hobbs: 0,
        logbookCategory: [],
        fromTemplate: null,
        description: prop.descriptionPreFill ?? "",
        saveAsTemplate: false,
        templateName: null,
        maintainerFullLegalName: "",
        maintainerFirstName: "",
        mi: null,
        maintainerLastName: "",
        maintainerId: "",
        certificate: "",
        oilChange: false,
        oilChange_engine2: false,
        engine_annual: false,
        engine2_annual: false,
        airframe_annual: false,
        signature: null,
        tt: 0,
        tsmoh: 0,
        tsmoh_engine2: 0
    });

    const [templates, setTemplates] = useState<any[]>([]);
    const db = getFirestore()
    const auth = getAuth()
    const { showOverlay, hideOverlay } = useLoadingOverlay();
    const [customErrors, setcustomErrors] = useState("");
    const [showSquawks, setShowSquawks] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [showAddMxMinder, setShowAddMxMinder] = useState(false);
    const [showPdfGenerator, setShowPdfGenerator] = useState(false);
    const [additionalLinks, setAdditionalLinks] = useState<any[]>([]);
    const [showFinalScreen, setShowFinalScreen] = useState(false)

    const [mxId, setMxId] = useState<string>()

    const { data: aircraft, isLoading: isLoadingAircraft } = useAircraftById(prop.aircraftId)
    const { data: user } = useUserById(auth.currentUser?.email!)

    const TachtimeError = "Tach time should be higher than current Tach value. Please select a past date to add a historical data."
    const HobbsError = "Hobbs should be higher than current Tach value. Please select a past date to add a historical data."

    useEffect(() => {
        getAllTemplate()
    }, [])

    useEffect(() => {
        let data = { ...formValues }

        if (aircraft) {
            data = {
                ...data,
                hobbs: aircraft.hobbs,
                tachtime: aircraft.currentTach,
                tachtime2: aircraft.currentTach_engine2 ?? 0,
            }
        }

        if (user) {
            data = {
                ...data,
                "maintainerFullLegalName": user?.fullLegalName,
                "maintainerFirstName": user?.firstName,
                "maintainerLastName": user?.lastName,
                "certificate": user?.maintainerInfo?.certificate,
                "maintainerId": user?.id
            }
        }

        setFormValues(data)
    }, [aircraft, user])

    useEffect(() => {
        if (aircraft) {
            if (!isHistoryDate(aircraft?.lastMxDate, formValues.date)) {
                if (aircraft.hourMeter === "TACH") {
                    if (aircraft.currentTach > formValues.tachtime) setcustomErrors(TachtimeError)
                    else if (aircraft.dualEngine && aircraft.currentTach_engine2 > formValues.tachtime2) setcustomErrors(TachtimeError)
                    else setcustomErrors("")
                }
                else if (aircraft.hourMeter === "HOBBS") {
                    if (aircraft.hobbs > formValues.hobbs) setcustomErrors(HobbsError)
                    else setcustomErrors("")
                }
                else if (aircraft.hourMeter === "BOTHTACHHOBBS") {
                    if (aircraft.currentTach > formValues.tachtime) setcustomErrors(TachtimeError)
                    else if (aircraft.dualEngine && aircraft.currentTach_engine2 > formValues.tachtime2) setcustomErrors(TachtimeError)

                    else if (aircraft.hobbs > formValues.hobbs) setcustomErrors(HobbsError)
                    else setcustomErrors("")
                }
            } else {
                setcustomErrors("");
            }
        } else {
            setcustomErrors("");
        }
    }, [formValues])

    const getAllTemplate = async () => {

        const data: any[] = [];

        const q = query(collection(db, MxLogbookTemplateCollection));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push(doc.data());
        });

        setTemplates(data);
    }

    const [formErrors, setFormErrors] = useState({
        date: false,
        ia: false,
        aAndP: false,
        owner: false,
        tachtime: false,
        tachtime2: false,
        "hobbs": false,
        "logbookCategory": false,
        "fromTemplate": false,
        "description": false,
        "saveAsTemplate": false,
        "templateName": false,
        "maintainerFullLegalName": false,
        "maintainerFirstName": false,
        "mi": false,
        "maintainerLastName": false,
        "certificate": false
    });

    const handleChange = (e: any) => {

        const { name, value } = e.target;

        if (typeof (value) != "object") {
            if (value.trim() === '') {
                setFormErrors({
                    ...formErrors,
                    [name]: true,
                });
            } else {
                setFormErrors({
                    ...formErrors,
                    [name]: false,
                });
            }
        }

        var custom = {}
        // custom updates
        if (name == 'fromTemplate') {
            const tempData = templates.find(a => a.templateName === value)
            custom = {
                description: formValues.description + '\n' + tempData.value
            }
        }

        setFormValues({
            ...formValues,
            ...custom,
            [name]: value,
        });
    };

    const handleChangeCheckbox = (e: any) => {
        const { name, checked } = e.target;

        var custom = {}
        // custom updates
        if (checked && name == 'airframe_annual') {
            custom = {
                description: formValues.description + '\n' + "Performed airframe annual inspection."
            }
        } else if (checked && name == 'oilChange') {
            custom = {
                description: formValues.description + '\n' + "Performed engine oil change."
            }
        } else if (checked && name == 'engine_annual') {
            custom = {
                description: formValues.description + '\n' + "Performed engine annual inspection."
            }
        } else if (checked && name == 'oilChange_engine2') {
            custom = {
                description: formValues.description + '\n' + "Performed engine 2 oil change."
            }
        } else if (checked && name == 'engine2_annual') {
            custom = {
                description: formValues.description + '\n' + "Performed engine 2 annual inspection."
            }
        }

        setFormValues({
            ...formValues,
            ...custom,
            [name]: checked,
        });
    };

    const handleChangeNumber = (e: any) => {

        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: parseFloat(value),
        });
    };

    const handleClickSubmit = async (e: any) => {
        e.preventDefault();

        setShowConfirmation(true)
    }

    const isHistoryLog = (date: string) => getDateDiff(aircraft?.lastMxDate!, date) < 0;

    const isHistoryDate = (aircraftLastDate: string, logDate: string) => {
        return getDateDiff(aircraftLastDate, logDate) < 0
    }

    // Diff
    const getTachDiff = () => aircraft?.currentTach ? formValues.tachtime - aircraft?.currentTach : 0
    const getTach2Diff = () => aircraft?.currentTach_engine2 ? formValues.tachtime2 - aircraft?.currentTach_engine2 : 0
    const getHobbsDiff = () => aircraft?.hobbs ? formValues.hobbs - aircraft?.hobbs : 0

    const getTsmoh = () => {
        let value = 0;
        if (aircraft?.hourMeter == "TACH") value = aircraft.tsmoh + getTachDiff()
        else if (aircraft?.hourMeter == "HOBBS") value = aircraft.tsmoh + getHobbsDiff()
        else if (aircraft?.hourMeter == "BOTHTACHHOBBS") value = aircraft.tsmoh + getTachDiff()
        return parseFloat(value.toFixed(1));
    }

    const getTsmohEngine2 = () => {
        let value = 0;
        if (aircraft?.hourMeter == "TACH") value = aircraft.tsmoh_engine2 + getTach2Diff()
        else if (aircraft?.hourMeter == "HOBBS") value = aircraft.tsmoh_engine2 + getHobbsDiff()
        else if (aircraft?.hourMeter == "BOTHTACHHOBBS") value = aircraft.tsmoh_engine2 + getTach2Diff()
        return parseFloat(value.toFixed(1));
    }

    const getTT = () => {
        let value = 0;
        if (aircraft?.hourMeter == "TACH") value = aircraft.totalTime + (getTachDiff() > getTach2Diff() ? getTachDiff() : getTach2Diff())
        else if (aircraft?.hourMeter == "HOBBS") value = aircraft.totalTime + getHobbsDiff()
        else if (aircraft?.hourMeter == "BOTHTACHHOBBS") value = aircraft.totalTime + getHobbsDiff()
        return parseFloat(value.toFixed(1));
    }

    const handleSubmit = async (isContinue: boolean) => {
        if (isContinue) {
            // e.preventDefault();
            showOverlay()
            const flight = {
                ...formValues,
                aircraftId: prop.aircraftId,
                tt: getTT(),
                tsmoh: getTsmoh(),
                tsmoh_engine2: aircraft?.dualEngine ? getTsmohEngine2() : 0,
                additionalLinks: additionalLinks,
            }

            var res = await addDoc(collection(db, MxLogbookCollection), flight);

            if (flight.saveAsTemplate) {
                const temp = {
                    templateName: flight.templateName,
                    value: flight.description
                }

                await addDoc(collection(db, MxLogbookTemplateCollection), temp);
            }

            // update aircraft here
            if (!isHistoryLog(formValues.date)) {
                // const docRef = doc(db, AircraftCollection, prop.aircraftId);
                // const docSnap = await getDoc(docRef);
                // let aircraftData = docSnap.data();
                var aircraftData: any = {
                    ...aircraft,
                    currentTach: toFixedNumber(flight.tachtime),
                    currentTach_engine2: toFixedNumber(flight.tachtime2),
                    hobbs: toFixedNumber(flight.hobbs) ?? aircraft?.hobbs,
                    tsmoh: getTsmoh(),
                    tsmoh_engine2: aircraft?.dualEngine ? getTsmohEngine2() : 0,
                    totalTime: getTT(),
                    lastMxDate: formValues.date
                }

                if (flight.oilChange) {
                    aircraftData = {
                        ...aircraftData,
                        lastOilChangeDate: flight.date,
                        tachTimeAtLastOilChange: aircraft?.hourMeter === "HOBBS" ? toFixedNumber(flight.hobbs) : toFixedNumber(flight.tachtime)
                    }
                }
                if (flight.oilChange_engine2) {
                    aircraftData = {
                        ...aircraftData,
                        lastOilChangeDate_engine2: flight.date,
                        tachTimeAtLastOilChange_engine2: aircraft?.hourMeter === "HOBBS" ? toFixedNumber(flight.hobbs) : toFixedNumber(flight.tachtime2)
                    }
                }
                if (flight.airframe_annual) {
                    aircraftData = {
                        ...aircraftData,
                        lastAnnualDate: flight.date,
                    }
                }

                if (flight.engine_annual) {
                    aircraftData = {
                        ...aircraftData,
                        engineLastAnnualDate: flight.date,
                    }
                }
                if (flight.engine2_annual) {
                    aircraftData = {
                        ...aircraftData,
                        engineLastAnnualDate_engine2: flight.date,
                    }
                }
                await setDoc(doc(db, AircraftCollection, prop.aircraftId), aircraftData)
            }

            // Fix squawk
            await Promise.all(
                (prop.squawk ?? []).map(async (squawk: any) => {
                    if (squawk !== null && squawk !== undefined) {
                        let squawkToSave = {
                            ...squawk,
                            status: "Completed",
                            mxId: res.id
                        }

                        return await setDoc(doc(db, SquawkCollection, squawk.id), squawkToSave)
                    }
                }))

            // Fix AD
            await Promise.all((prop.ad ?? []).map(async (adItem: any) => {
                if (adItem !== null && adItem !== undefined) {
                    let squawkToSave = {
                        ...adItem,
                        complianceDate: formValues.date,
                        "aircraftTT": aircraft?.totalTime,
                        "actionTaken": formValues.description,
                        "maintainerName": formValues.maintainerFullLegalName,
                        "maintainerLicenseCertificateNumber": formValues.certificate,
                        cw: true
                    }

                    await setDoc(doc(db, AircraftADCompliancesCollection, adItem.id), squawkToSave)
                }
            }))

            //Fix notification
            await Promise.all(
                (prop.notification ?? []).map(async (noti: any) => {
                    if (noti !== null && noti !== undefined) {
                        let notificationToSave = {
                            ...noti,
                            "date": new Date().toISOString().split("T")[0],
                            "aircraftTT": aircraft?.totalTime,
                            mxId: res.id
                        }

                        return await setDoc(doc(db, NotificationsCollection, noti.id), notificationToSave)
                    }
                }))

            setMxId(res.id)
            await addMxSignature(res.id);

            setShowFinalScreen(true)

            hideOverlay()
            // prop.onClose(false);
            setShowConfirmation(false)
        } else {
            setShowConfirmation(false)
        }
    };

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    const handleMultifileUpload = (e: any) => {
        const { name, value } = e;

        setFormValues({
            ...formValues,
            attachments: formValues.attachments ? [...formValues.attachments, ...value] : value,
        });
    }

    return (
        <>
            <Modal
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card className='signup-card w-[90vw]' sx={style}>
                    <div className='flex  flex-row justify-between'>
                        <div className="flex mb-5 gap-5">
                            <h1 className="text-3xl">Add MX Log</h1>
                            {/* <CustomButton text='SQUAWKS' onClick={() => { setShowSquawks(true) }} /> */}
                            <CustomButton text='+ Add MX Minder' onClick={() => { setShowAddMxMinder(true) }} />
                        </div>

                        <CloseIcon className='hover:cursor-pointer' onClick={() => prop.onClose(false)}></CloseIcon>
                    </div>
                    {!isLoadingAircraft && aircraft && <div className="h-[80vh] overflow-scroll">
                        <h4 className="flex flex-row"><b>Aircraft Id:</b> {aircraft?.aircraftId}  {!isHistoryLog(formValues.date) && <div>  | <b>AIRFRAME TT (Total Time):</b> {getTT()} | <b>ENGINE TSMOH:</b>  {getTsmoh()}<sup>(E1)</sup> {aircraft?.dualEngine ? `, ${getTsmohEngine2()}` : ""}{aircraft?.tsmoh_engine2 ? <sup>(E2)</sup> : null}</div>}</h4>
                        {customErrors != "" && <Alert severity="error"><span className="text-[red] mb-4">{customErrors}</span></Alert>}
                        {isHistoryLog(formValues.date) && <Alert severity="info"><span className="text-[black] mb-4">This is a history log. No aircraft data will be changed.</span></Alert>}
                        <div className=' px-3 pt-2'>

                            {aircraft?.lastMxDate && getDateDiff(aircraft?.lastMxDate, new Date().toISOString().split("T")[0]) < 0 && <pre className="text-[10px] justify-right">[ last Mx log added on: {aircraft?.lastMxDate} ]</pre>}
                            <Box
                                component="form"
                                sx={{ flexGrow: 1 }}
                                // onSubmit={handleSubmit}
                                onSubmit={handleClickSubmit}
                                className="mt-4"
                            >
                                {/* ####################### Airframe & Engines ####################### */}
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <TextField
                                            type="date"
                                            name="date"
                                            value={formValues.date}
                                            onChange={handleChange}
                                            label="Date"
                                            error={formErrors.date}
                                            helperText={formErrors.date ? 'Date is required' : ''}
                                            className="w-full"
                                            InputProps={{ inputProps: { max: new Date().toISOString().split("T")[0] } }}
                                            required
                                            disabled={!!mxId}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <InputLabel id="dropdown-label" sx={{ fontSize: '0.7rem' }}>Logbook Category</InputLabel>
                                            <Select
                                                labelId="dropdown-label"
                                                name="logbookCategory"
                                                value={formValues.logbookCategory}
                                                label="Select Option"
                                                onChange={handleChange}
                                                required
                                                multiple
                                                className="h-[50px]"
                                                sx={{ fontSize: '0.8rem' }}
                                                disabled={!!mxId}
                                            >
                                                {logBookCategory.map(l => <MenuItem value={l.value} key={l.value}>{l.value}</MenuItem>)}
                                                {aircraft?.dualEngine && <MenuItem key={"Engine2"} value={"Engine 2"}>{"Engine 2"}</MenuItem>}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {aircraft?.hourMeter !== "TACH" && < Grid item xs={3}>
                                        <TextField
                                            label="Hobbs"
                                            name="hobbs"
                                            type="number"
                                            value={formValues.hobbs}
                                            onChange={handleChangeNumber}
                                            // error={formErrors.hobbs}
                                            className="w-full"
                                            disabled={!!mxId}
                                        />
                                    </Grid>}
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            control={<Checkbox checked={formValues.airframe_annual} onChange={handleChangeCheckbox} name="airframe_annual" />}
                                            label="Reset Airframe Annual Date"
                                            disabled={!!mxId || isHistoryDate(aircraft?.lastAnnualDate, formValues.date)}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <p className='font-bold mb-[-9px]'>
                                            Engine 1
                                        </p>
                                    </Grid>
                                    {aircraft?.hourMeter !== "HOBBS" && <Grid item xs={3}>
                                        <TextField
                                            label="Tach Time"
                                            name="tachtime"
                                            type="number"
                                            value={formValues.tachtime}
                                            onChange={handleChangeNumber}
                                            error={formErrors.tachtime}
                                            helperText={formErrors.tachtime ? 'Tach Time is required' : ''}
                                            required
                                            className="w-full"
                                            disabled={!!mxId}
                                        // inputProps={{ min: minTach }}
                                        />
                                    </Grid>}

                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            control={<Checkbox checked={formValues.oilChange} onChange={handleChangeCheckbox} name="oilChange" />}
                                            label="Reset Engine 1 Oil change"
                                            disabled={!!mxId || isHistoryDate(aircraft?.lastOilChangeDate, formValues.date)}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            control={<Checkbox checked={formValues.engine_annual} onChange={handleChangeCheckbox} name="engine_annual" />}
                                            label="Reset Engine Annual Date"
                                            disabled={!!mxId || isHistoryDate(aircraft?.engineLastAnnualDate, formValues.date)}
                                        />
                                    </Grid>

                                    {aircraft?.dualEngine && <Grid item xs={12}>
                                        <p className='font-bold mb-[-9px]'>
                                            Engine 2
                                        </p>
                                    </Grid>}
                                    {aircraft?.dualEngine && <Grid item xs={3}>
                                        <TextField
                                            label="Tach Time"
                                            name="tachtime2"
                                            type="number"
                                            value={formValues.tachtime2}
                                            onChange={handleChangeNumber}
                                            error={formErrors.tachtime}
                                            helperText={formErrors.tachtime2 ? 'Tach Time is required' : ''}
                                            required
                                            className="w-full"
                                            disabled={!!mxId}
                                        // inputProps={{ min: minTach2 }}

                                        />
                                    </Grid>}
                                    {
                                        aircraft?.dualEngine && <Grid item xs={3}>
                                            <FormControlLabel
                                                control={<Checkbox checked={formValues.oilChange_engine2} onChange={handleChangeCheckbox} name="oilChange_engine2" />}
                                                label="Reset Engine 2 Oil change"
                                                disabled={!!mxId || isHistoryDate(aircraft?.lastOilChangeDate_engine2, formValues.date)}
                                            />
                                        </Grid>
                                    }
                                    {
                                        aircraft?.dualEngine && <Grid item xs={3}>
                                            <FormControlLabel
                                                control={<Checkbox checked={formValues.engine2_annual} onChange={handleChangeCheckbox} name="engine2_annual" />}
                                                label="Reset Engine 2 Annual Date"
                                                disabled={!!mxId || isHistoryDate(aircraft?.engineLastAnnualDate_engine2, formValues.date)}
                                            />
                                        </Grid>
                                    }

                                    {/* ####################### Description ####################### */}
                                    <Grid item xs={12}>
                                        <CustomDivider />
                                        <p className='font-bold'>
                                            Description
                                        </p>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormControl fullWidth>
                                            <InputLabel id="dropdown-label" sx={{ fontSize: '0.7rem' }}>Select From Template</InputLabel>
                                            <Select
                                                labelId="dropdown-label"
                                                name="fromTemplate"
                                                value={formValues.fromTemplate}
                                                label="Select Option"
                                                onChange={handleChange}
                                                className="h-[50px] text-[10px]"
                                                sx={{ fontSize: '0.8rem' }}
                                                disabled={!!mxId}
                                            >
                                                {templates.map(l => <MenuItem key={l.templateName} value={l.templateName}>{l.templateName}</MenuItem>)}

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={7}></Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Description"
                                            name="description"
                                            value={formValues.description}
                                            onChange={handleChange}
                                            error={formErrors.description}
                                            className="w-full custom-textarea"
                                            rows={10}
                                            multiline
                                            disabled={!!mxId}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            control={<Checkbox checked={formValues.saveAsTemplate} onChange={handleChangeCheckbox} name="saveAsTemplate" />}
                                            label="Save description as a Template"
                                            disabled={!!mxId}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField
                                            label="Template Name (Applicable if save as a Template)"
                                            name="templateName"
                                            value={formValues.templateName}
                                            onChange={handleChange}
                                            error={formErrors.templateName}
                                            className="w-full"
                                            disabled={!!mxId}
                                        />
                                    </Grid>

                                    <Grid item xs={5}></Grid>

                                    <Grid item xs={12}>
                                        <CustomDivider />
                                        {/* <p className='font-bold'>
                                            Additional Details
                                        </p> */}
                                    </Grid>

                                    {/* ####################### Additional Links ####################### */}
                                    <Grid item xs={12}>
                                        <div>
                                            <h6 className="font-bold">Additional Links
                                                <AddLinkIcon className="cursor-pointer ml-3" onClick={() => {
                                                    setAdditionalLinks([...additionalLinks, { title: "", url: "" }])
                                                }} /></h6>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {additionalLinks.map((l: any, index: number) =>
                                            <Grid container xs={12} spacing={1} className="pt-1" key={index}>
                                                <Grid item xs={3} >
                                                    <TextField
                                                        label={`Link Title`}
                                                        value={additionalLinks[index].title}
                                                        onChange={(e: any) => {
                                                            const updatedLinks = [...additionalLinks];
                                                            updatedLinks[index].title = e.target.value;
                                                            setAdditionalLinks(updatedLinks);
                                                        }}
                                                        className="w-full"
                                                    />
                                                </Grid>
                                                <Grid item xs={9} >
                                                    <TextField
                                                        label={`Link url`}
                                                        value={additionalLinks[index].url}
                                                        onChange={(e: any) => {
                                                            const updatedLinks = [...additionalLinks];
                                                            updatedLinks[index].url = e.target.value;
                                                            setAdditionalLinks(updatedLinks);
                                                        }}
                                                        className="w-full"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>

                                    {/* ####################### Attachments ####################### */}
                                    <Grid item xs={12}>
                                        <h4 className="font-bold">Attachments</h4>
                                        <MultiFileUploadWidget
                                            id="mx-attachments"
                                            label={"Upload Attachments"}
                                            folderName="MxDocuments"
                                            // value={formValues.image}
                                            onChange={(e: string[]) => handleMultifileUpload({ name: "attachments", value: e })}
                                        />
                                    </Grid>

                                    {/* ####################### Maintainer ####################### */}
                                    <Grid item xs={12}>
                                        <CustomDivider />
                                        <p className='font-bold'>
                                            Maintainer Details
                                        </p>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            label="Maintainer Full Legal Name"
                                            name="maintainerFullLegalName"
                                            value={formValues.maintainerFullLegalName}
                                            onChange={handleChange}
                                            error={formErrors.maintainerFullLegalName}
                                            className="w-full"
                                            required
                                            disabled={!!mxId}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            label="Maintainer First Name"
                                            name="maintainerFirstName"
                                            value={formValues.maintainerFirstName}
                                            onChange={handleChange}
                                            error={formErrors.maintainerFirstName}
                                            className="w-full"
                                            required
                                            disabled={!!mxId}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            label="MI"
                                            name="mi"
                                            value={formValues.mi}
                                            onChange={handleChange}
                                            error={formErrors.mi}
                                            className="w-full"
                                            required
                                            disabled={!!mxId}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            label="Maintainer Last Name"
                                            name="maintainerLastName"
                                            value={formValues.maintainerLastName}
                                            onChange={handleChange}
                                            error={formErrors.maintainerLastName}
                                            className="w-full"
                                            required
                                            disabled={!!mxId}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            label="Certificate"
                                            name="certificate"
                                            value={formValues.certificate}
                                            onChange={handleChange}
                                            error={formErrors.certificate}
                                            className="w-full"
                                            required
                                            disabled={!!mxId}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            control={<Checkbox checked={formValues.ia} onChange={handleChangeCheckbox} name="ia" />}
                                            label="IA"
                                            disabled={!!mxId}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            control={<Checkbox checked={formValues.aAndP} onChange={handleChangeCheckbox} name="aAndP" />}
                                            label="A&P"
                                            disabled={!!mxId}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            control={<Checkbox checked={formValues.owner} onChange={handleChangeCheckbox} name="owner" />}
                                            label="Owner"
                                            disabled={!!mxId}
                                        />
                                    </Grid>
                                </Grid>

                                <div className="mt-5 flex gap-3">
                                    {/* {mxId && <CustomButtonNonSubmit text='Print Preview' onClick={() => { setShowPdfGenerator(true) }} />} */}
                                    {!mxId && <CustomButtonFill text='Submit' onClick={() => { }} />}
                                </div>
                            </Box>
                        </div>
                    </div>}
                </Card>
            </Modal >

            {showSquawks && <SquawksModal aircraftId={prop.aircraftId} permissionPower={3} onClose={() => { setShowSquawks(false) }} />
            }
            {showConfirmation && <AddMxConfirmation onDecide={handleSubmit} />}
            {
                showAddMxMinder && < AddNotification aircraftId={prop.aircraftId} notification={null} onClose={() => {
                    setShowAddMxMinder(false)
                }} />
            }
            {
                showPdfGenerator && mxId && aircraft && <MxLogPdfGeneratorModal aircraft={aircraft} formValues={formValues}
                    tt={getTT()}
                    tsmoh={getTsmoh()}
                    tsmoh_engine2={aircraft?.dualEngine ? getTsmohEngine2() : 0}
                    mxId={mxId}
                    onClose={() => setShowPdfGenerator(false)} />
            }
            {showFinalScreen && <FinalScreen onclose={() => prop.onClose(false)} printPreview={() => setShowPdfGenerator(true)} />}
        </>)
}

export const AddMxConfirmation = ({ onDecide }: { onDecide: (isContinue: boolean) => void }) => {
    const [isAgreed, setIsAggreed] = useState(false)
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    return <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card className='max-h-[90vh]' sx={style}>
            <div className='flex  flex-row justify-between'>
                <h1 className="text-3xl mb-5">ADD MX LOG ENTRY</h1>
                <CloseIcon className='hover:cursor-pointer' onClick={() => onDecide(false)}></CloseIcon>
            </div>
            <p>By checking this box and submitting this maintenance entry, I certify that all work described has been completed in accordance with 14 C.F.R. Part 43 and § 91.417 and that this entry accurately reflects the work performed. I further certify that I am authorized to perform and approve this work under my current certificate and privileges.</p>
            <p className="mt-2"> This digital signature constitutes my official certification of the aircraft's airworthiness status and compliance with applicable FAA regulations. Once submitted, this entry will be permanently locked as a read-only document and cannot be modified.</p>

            <FormControlLabel
                className="mt-2"
                control={<Checkbox checked={isAgreed} onChange={(e) => setIsAggreed(e.target.checked)} name="isAgreed" />}
                label="I agree to digitally sign and certify this maintenance entry."
            />

            <div className="flex mt-5 gap-3">
                <CustomButtonFill text='Confirm' disabled={!isAgreed} onClick={async () => {
                    onDecide(true)
                }} />
                <CustomButton text="Cancel" onClick={() => onDecide(false)} />
            </div>

        </Card>
    </Modal>
}

export const FinalScreen = ({ onclose, printPreview }: { onclose: () => void, printPreview: () => void }) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 3,
    };

    return <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Card className='max-h-[90vh]' sx={style}>
            <div className='flex  flex-row justify-between'>
                <h2 className="text-3xl mb-5"><CheckCircleIcon fontSize="large" className="text-[green] mr-3" /> Successfully Logged</h2>
                {/* <CloseIcon className='hover:cursor-pointer' onClick={() => onclose()}></CloseIcon> */}
            </div>
            <div className="flex mt-5 gap-3">
                <CustomButtonFill text='Print Preview' onClick={async () => {
                    printPreview()
                }} />
                <CustomButton text="Done" onClick={() => onclose()} />
            </div>

        </Card>
    </Modal>
}


// aircraft 
// HOBBS => TT, TSMOH
// show only hobbs $$$$$
// min validation only hobbs

// TACH  => TT, TSMOH
// show only hobbs $$$$$
// min validation only hobbs

// BOTHTACHHOBBS  => HOBBS -- TT, TACH -- TSMOH
// show both  $$$$$
// min validation both