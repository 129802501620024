import { getAuth } from "firebase/auth";

export const sendEmail = async (email: string, name: string): Promise<boolean> => {
    const auth = getAuth();
    await auth.currentUser?.getIdToken(/* forceRefresh */ true).then(async (idToken) => {
        await fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL}/SendEmail`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                // email: 'amith.dmw@gmail.com',
                templateName: "SIGNUP_INVITATION",
                subject: `${name} has invited you to manage their aircraft on AirLogbooks!`
            }),
        });
    });

    return true;
}

export const sendEmailPilotShare = async (email: string, name: string): Promise<boolean> => {
    const auth = getAuth();
    await auth.currentUser?.getIdToken(/* forceRefresh */ true).then(async (idToken) => {
        await fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL}/SendEmail`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                // email: 'amith.dmw@gmail.com',
                templateName: "SIGNUP_INVITATION",
                subject: `${name} has invited you to share their pilot currency status on AirLogbooks!`
            }),
        });
    });

    return true;
}