import { collection, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import { NotificationModel } from "../models/notifications";
import { userKey, UserNotificationsCollection } from "../keys";

export const getAllUserNotifications_firebase = async (userId: string): Promise<NotificationModel[]> => {
    // const userId = localStorage.getItem(userKey)
    const db = getFirestore()
    const data: NotificationModel[] = [];
    if (userId) {
        const q = query(collection(db, UserNotificationsCollection), where("userId", "==", userId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id } as NotificationModel);
        });
    }

    return data;
}