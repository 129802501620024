import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import { AddNotification } from "./addNotification";
import EditIcon from '@mui/icons-material/Edit';
import { getSeverityBorderColor, getSeverityWithDateAndHours, getSeverityWithDateAndHoursString, InfoNotificationSeverityIcon, Severity } from "../../../components/severity";
import { useLoadingOverlay } from "../../../loading";
import { NotificationsCollection } from "../../../keys";
import { cn } from "../../../userData/userData";
import { AddMxLog } from "../mx/addMxLog";

export const NotificationList = ({ aircraftId, aircraft, showAll, permissionPower, onCloseMx, setNotificationSeverity }: {
    aircraftId: string, aircraft: any, showAll: boolean, permissionPower: number,
    onCloseMx: () => void, setNotificationSeverity: (severity: Severity) => void
}) => {
    const [notifications, setNotifications] = useState<any[]>([])
    const [showAddModal, setShowAddModal] = useState(false);
    const [showConfimComplete, setShowConfimComplte] = useState(false);
    const [selected, setSelected] = useState<any[]>([]);
    const { showOverlay, hideOverlay } = useLoadingOverlay();

    useEffect(() => {
        getAllNotifications()
    }, [])

    const getAllNotifications = async () => {
        const db = getFirestore()
        const data: any[] = [];

        const q = query(collection(db, NotificationsCollection), where("status", "==", "Pending"), where("aircraftId", "==", aircraftId));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            data.push({
                ...doc.data(), id: doc.id,
                severity: getSeverityWithDateAndHours(doc.data().notifyInDays, doc.data().date, doc.data().notifyInHours, doc.data().aircraftTT, aircraft.totalTime),
                scheduleString: getSeverityWithDateAndHoursString(doc.data().notifyInDays, doc.data().date, doc.data().notifyInHours, doc.data().aircraftTT, aircraft.totalTime),
            });
        });

        setNotificationSeverity(data.some((a: any) => a.severity == Severity.High) ? Severity.High : data.some((a: any) => a.severity == Severity.Medium) ? Severity.Medium : Severity.Low);
        data.sort((a: any, b: any) => b.severity - a.severity);
        setNotifications(data);
    }

    const filteredNotifications = showAll
        ? notifications
        : notifications.filter((notification) => notification.severity !== Severity.Done);

    return <>
        <div className="pr-2">
            <div className="flex py-1 mb-3 sticky top-0 bg-gray-100 z-10">
                <span className="text-[14px] font-bold">MX Minders</span>
                <div className="mt-[-3px]">
                    {permissionPower >= 4 && <Tooltip title="Add notification" arrow>
                        <div className="w-[25px] cursor-pointer" onClick={() => { setShowAddModal(true) }} ><AddAlertIcon /></div>
                    </Tooltip>}
                </div>
                {selected.length > 0 && <button
                    className="ml-auto border border-[#4995CF] border-[1px] rounded-full h-[26px] px-2 align-center bg-[#3A8CC9] text-[#ffffff] text-[12px]"
                    onClick={() => { setShowConfimComplte(true) }}>
                    Fix Mx Minder(s) - ({selected.length})
                </button>}
            </div>

            {filteredNotifications.map((value, i) => {
                return <div key={i} className={cn("flex flex-col md:flex-row border-2 p-[2px] rounded-md mb-1 ", getSeverityBorderColor(value.severity))}>
                    <div className="flex flex-row items-center w-[100%]">
                        <InfoNotificationSeverityIcon severity={value.severity} />
                        <p className="text-gray-700 text-[12px] ml-2">{value.date}</p>
                        <p className="flex-1 text-gray-700 text-[12px] ml-2 hidden md:block">
                            {value.description} - <b>{value.scheduleString}</b>
                        </p>
                        <p className="text-gray-700 text-[12px] ml-2 mr-2">{value.pilot}</p>
                        {permissionPower >= 4 && <div className="cursor-pointer ml-auto">
                            <EditIcon sx={{ width: "20px" }} className="ml-1 mr-2 p-0 cursor-pointer" onClick={() => {
                                setSelected([value])
                                setShowAddModal(true)
                            }} />
                            <FormControlLabel
                                control={<Checkbox size="small" checked={selected.some((ele: any) => ele.id === value.id)} onChange={async (e: any) => {
                                    if (e.target.checked) {
                                        await setSelected([...selected, value])
                                    } else {
                                        await setSelected(selected.filter((ele: any) => ele.id !== value.id))
                                    }
                                }} name="fixNotification" />}
                                label=""
                            />
                        </div>}
                    </div>

                    <p className="text-gray-700 text-[12px] md:mt-0 md:ml-2 block md:hidden">{value.description} - <b>{value.scheduleString}</b></p>
                </div>
            })
            }

            {
                notifications.length == 0 && <p className="text-[13px] text-[green]">No overdue MX Minders</p>
            }

            {
                filteredNotifications.length == 0 && <p className="text-[13px] text-[green]">No overdue critical MX Minders</p>
            }

            {
                showAddModal && <AddNotification aircraftId={aircraftId} notification={selected[0]} onClose={() => {
                    setShowAddModal(false)
                    getAllNotifications()
                    setSelected([])
                }} />
            }

            {
                showConfimComplete && <AddMxLog onClose={() => {
                    onCloseMx()
                    setSelected([])
                    setShowConfimComplte(false)
                    getAllNotifications()
                }} aircraftId={aircraftId}
                    descriptionPreFill={"MX minder " + selected.map(item => item.description).join(", ")}
                    notification={selected}
                />
            }
        </div>
    </>
}
